import { Storage } from "@capacitor/storage";
import { deepMerge } from ".";

const CACHE_KEY = "request";

interface Request {
  requestId: string;
  [key: string]: any;
}

export class RequestsQueue {
  clearRequests = async () => {
    await Storage.set({ key: CACHE_KEY, value: JSON.stringify([]) });
  };
  getAllRequests = async () => {
    const { value } = await Storage.get({ key: CACHE_KEY });
    if (!value) return [];
    return JSON.parse(value);
  };
  mergeRequests = (oldRequest: Request, newRequest: Request) => {
    const request: Request = deepMerge(oldRequest, newRequest);
    return request;
  };
  saveLatestRequest = (store: Request[], newRequest: Request) => {
    if (store.some((request) => request.requestId === newRequest.requestId)) {
      return store.map((request) =>
        request.requestId === newRequest.requestId
          ? this.mergeRequests(request, newRequest)
          : request
      );
    }
    return [...store, newRequest];
  };
  saveRequest = async (
    newRequest: Request,
    notificationCb: (message: string) => void
  ) => {
    let store: Request[] = await this.getAllRequests();
    store = this.saveLatestRequest(store, newRequest);
    await Storage.set({
      key: CACHE_KEY,
      value: JSON.stringify(store),
    });
    notificationCb(
      "Request saved and will be sent once your device has internet connection"
    );
  };
  hasRequests = async () => {
    const requests = await this.getAllRequests();
    return requests.length > 0;
  };
  popRequestsInState = (requests: Request[]) => {
    return requests.shift();
  };
  hasRequestsInState = (requests: Request[]) => {
    return requests.length > 0;
  };
  callNetworkRequest = async (
    requestCb: (payload?: Request) => Promise<void>,
    notificationCb: (message: string) => void
  ) => {
    const requests = await this.getAllRequests();
    const hasRequests = await this.hasRequests();
    const failedRequestMap: Record<string, number> = {};
    while (this.hasRequestsInState(requests)) {
      const request = this.popRequestsInState(requests);
      try {
        await requestCb(request);
      } catch (e) {
        if (!!request) {
          failedRequestMap[request.requestId] =
            (failedRequestMap[request.requestId] || 0) + 1;
          if (failedRequestMap[request.requestId] < 3) {
            requests.push(request);
          } else {
            notificationCb("Something went wrong");
          }
        }
      }
    }
    if (hasRequests) {
      notificationCb("All saved requests have been sent!");
      await this.clearRequests();
    }
  };
}
