import React, { FC, Fragment } from "react";
import { Redirect } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { GET_AUTH_CONFIG } from "../domains/queries";

// For routes that can only be accessed by authenticated users
const NoAuthGuard: FC = ({ children }) => {
  const { data: auth } = useQuery(GET_AUTH_CONFIG);
  const { authConfig } = auth;
  if (authConfig.isSignedIn) {
    return <Redirect to="/projects" />;
  }
  return <Fragment>{children}</Fragment>;
};

export default NoAuthGuard;
