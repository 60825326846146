import React from "react";
import async from "../../components/Async";
import { Briefcase } from "react-feather";
import AuthGuard from "./guards";
import { RoutePaths } from "../../pages/constants";

const Projects = async(() => import("../../pages/pages/Projects"));

export const projectsRoutes = {
  id: "Project",
  path: RoutePaths.Projects,
  icon: <Briefcase />,
  component: Projects,
  containsHome: true,
  children: null,
  guard: AuthGuard,
};
