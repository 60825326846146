import React from "react";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import Helmet from "react-helmet";
import { Button as MuiButton, Typography } from "@material-ui/core";
import { spacing } from "@material-ui/system";
import { MuiButtonSpacingType } from "../types";
import { useTranslation } from "react-i18next";

const Button = styled(MuiButton)<MuiButtonSpacingType>(spacing);

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`;

function Page404() {
  const { t } = useTranslation();
  return (
    <Wrapper>
      <Helmet title="404 Error" />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        404
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t("Page not found")}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t("The page you are looking does not exist")}
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="primary"
        mt={2}
      >
        {t("Return to App")}
      </Button>
    </Wrapper>
  );
}

export default Page404;
