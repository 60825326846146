import { useEffect, FC } from "react";
import { useHistory } from "react-router-dom";
import { App } from "@capacitor/app";

const AppUrlListener: FC<any> = () => {
  let history = useHistory();
  useEffect(() => {
    App.addListener("appUrlOpen", (data: any) => {
      // Example url: https://beerswift.app/tabs/tab2
      // slug = /tabs/tab2
      const slug = data.url.split(".app").pop();
      if (slug) {
        history.push(slug);
      }
    });
  }, [history]);

  return null;
};

export default AppUrlListener;
