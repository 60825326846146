import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { OpportunityConfigProps } from "../../pages/types";
import { updateSelectedOpportunity } from "../mutations";

const GET_AN_OPPORTUNITY = gql`
  query GetOpportunity($id: ID!) {
    opportunity: getOpportunity(id: $id) {
      remodelingType
      ownershipType
      bathroomFloor
      bathroomSize
      name
      accountId
    }
  }
`;

const GET_OPPORTUNITY_CONFIG = gql`
  query GetOpportunityConfig {
    opportunityConfig @client
  }
`;

export const useGetAnOpportunity = (onSuccessCb?: (data: any) => void) => {
  const [getAnOpportunity, { loading: opportunityLoader }] = useLazyQuery(
    GET_AN_OPPORTUNITY,
    {
      onCompleted: (data) => {
        if (data?.opportunity) {
          updateSelectedOpportunity(data.opportunity);
          onSuccessCb?.(data.opportunity);
        }
      },
    }
  );

  return {
    getAnOpportunity,
    opportunityLoader,
  };
};

export const useOpportunityConfig = () => {
  const {
    data: opportunityConfigData,
    loading: opportunityConfigLoader,
  } = useQuery<{
    opportunityConfig: OpportunityConfigProps;
  }>(GET_OPPORTUNITY_CONFIG);

  return {
    opportunityConfig: opportunityConfigData?.opportunityConfig,
    opportunityConfigLoader,
  };
};
