import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { updateAccount } from "domains/mutations";

import { AppConfigProps } from "../../pages/types";

export const GET_APP_CONFIG = gql`
  query GetAppConfig {
    appConfig @client
  }
`;

export const GET_ACCOUNT = gql`
  query GetAccount {
    account: getAccount {
      id
      ownerId
      name
      billingAddress {
        city
        latitude
        longitude
      }
    }
  }
`;

export const useAppConfig = () => {
  const { data: appConfigData, loading: appConfigLoader } = useQuery<{
    appConfig: AppConfigProps;
  }>(GET_APP_CONFIG);

  return {
    appConfigLoader,
    appConfig: appConfigData?.appConfig,
  };
};

export const useGetAccount = () => {
  const [getAccount, { loading: getAccountLoader }] = useLazyQuery(
    GET_ACCOUNT,
    {
      onCompleted: (data: any) => {
        updateAccount(data?.account);
      },
    }
  );
  return {
    getAccount,
    getAccountLoader,
  };
};
