import { gql, useMutation } from "@apollo/client";

import { appointmentConfigVar } from "../local";

const UPSERT_APPOINTMENT = gql`
  mutation UpsertAppointment($input: AppointmentInput!) {
    appointment: upsertAppointment(input: $input) {
      id
      name
      type
      start
      end
      fullDay
      description
      projectId
      quoteId
    }
  }
`;

export const updateSelectedAppointment = (selectedAppointment: any) => {
  const current = appointmentConfigVar();
  appointmentConfigVar({
    ...current,
    selectedAppointment,
  });
};

export const populateAppointments = (appointments: any = []) => {
  const current = appointmentConfigVar();
  appointmentConfigVar({
    ...current,
    appointments,
  });
};

export const resetAppointments = () => {
  populateAppointments();
  updateSelectedAppointment({});
};

const updateAppointment = (appointment: any) => {
  const current = appointmentConfigVar();
  appointmentConfigVar({
    ...current,
    appointments: current.appointments?.map((curAppointment) =>
      curAppointment.id === appointment.id ? appointment : curAppointment
    ),
  });
};

export const addToAppointments = (appointment: any) => {
  const current = appointmentConfigVar();
  appointmentConfigVar({
    ...current,
    appointments: [...current.appointments, appointment],
  });
};

export const useUpsertAppointment = ({
  isEditing = false,
  onSuccessCallback,
  onErrorCallback,
}: {
  isEditing?: boolean;
  onSuccessCallback: () => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [upsertAppointment, { loading: upsertAppointmentLoader }] = useMutation(
    UPSERT_APPOINTMENT,
    {
      onCompleted: (data) => {
        if (data?.appointment) {
          updateSelectedAppointment(data.appointment);
          if (isEditing) {
            updateAppointment(data.appointment);
          } else {
            addToAppointments(data.appointment);
          }
          onSuccessCallback();
        }
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    }
  );

  return {
    upsertAppointment,
    upsertAppointmentLoader,
  };
};
