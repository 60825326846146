/* eslint-disable prettier/prettier */
import {
  DOC_MAPPER,
  DocTypesMapper,
  PROJECT_LIST_STATUS,
  getProjectStatusFromInternalStatus,
} from "../../pages/constants";
import {
  DocumentProps,
  MaterialProp,
  ProjectProps,
  QuoteProps,
} from "../../pages/types";
import { getCurrentTime, getUnique, groupBy } from "../../pages/utils";
import { gql, useMutation } from "@apollo/client";

import { projectConfigVar } from "../local";

const UPSERT_PROJECT = gql`
  mutation UpsertProject($input: ProjectInput!) {
    project: upsertProject(input: $input) {
      id
      customerNumber
      craftsmanEmail
      internalStatus
      prepayment
      salesforceQuoteId
      projectStartDate
      projectEndDate
      updatedAt
      internalApproved
      wizardMaterials
      invoice {
        defects
        invoiceNumber
        totalPrice
        materials {
          quantity
          price
          material {
            id
            name
            description
          }
          craftsmanDescription
          craftsmanId
          craftsmanName
          craftsmanPrice
        }
        signatures {
          craftsman {
            id
            signatureUrl
          }
          caregiver1 {
            id
            signatureUrl
          }
          caregiver2 {
            id
            signatureUrl
          }
          caregiver3 {
            id
            signatureUrl
          }
        }
      }
      internalQuote {
        quoteNumber
        validityDate
        totalPrice
        materials {
          quantity
          price
          material {
            id
            name
            description
          }
          craftsmanDescription
          craftsmanId
          craftsmanName
          craftsmanPrice
        }
        contact {
          caregiver1 {
            id
            tenantNumber
            insuranceNumber
          }
          caregiver2 {
            id
            tenantNumber
            insuranceNumber
          }
          caregiver3 {
            id
            tenantNumber
            insuranceNumber
          }
        }
        terms {
          financialSupportPurpose {
            answer1
            answer2
            answer3
          }
          receivingCareServices {
            answer1
            answer2
            answer3
          }
          caregiverQuoteAgreement {
            accepted
            decisionTime
          }
        }
        signatures {
          craftsman {
            id
            signatureUrl
          }
          caregiver1 {
            id
            signatureUrl
          }
          caregiver2 {
            id
            signatureUrl
          }
          caregiver3 {
            id
            signatureUrl
          }
        }
      }
    }
  }
`;

const UPLOAD_FILES = gql`
  mutation UploadMultipleFiles($files: [Upload]!) {
    uploadedFiles: uploadMultipleFiles(files: $files) {
      success
      message
      filename
      encoding
      mimetype
      location
    }
  }
`;

const UPLOAD_FILE = gql`
  mutation UploadFile($file: Upload!) {
    uploadedFile: uploadFile(file: $file) {
      success
      message
      filename
      encoding
      mimetype
      location
    }
  }
`;

const UPSERT_ATTACHMENT = gql`
  mutation UpsertAttachment($input: AttachmentInput!) {
    attachment: upsertAttachment(input: $input) {
      documentUrl
      documentName
      documentType
    }
  }
`;

export const updateSelectedQuoteId = (selectedQuoteId: string) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    selectedQuoteId,
  });
};

export const updateSelectedProject = (project: any) => {
  const current = projectConfigVar();
  const selectedProject = project ? project : {};
  projectConfigVar({
    ...current,
    selectedProject,
  });
};
export const updateNextCustAndQuoteNo = (nextCustAndQuote: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    nextCustAndQuote,
  });
};
export const updateSelectedDocumentLocation = (currDocLocation: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    currDocLocation,
  });
};

const MergedDocuments = ["offerDocuments", "invoiceDocuments"];
const mergedDocumentName = (documentName: string) => {
  if (documentName === "offerDocuments") {
    return "Angebotsmappe";
  }
  return "Bauabschluss";
};

export const updateSelectedDocument = (updatedDocument: DocumentProps) => {
  const current = projectConfigVar();
  let selectedDocument = updatedDocument;
  if (MergedDocuments.includes(selectedDocument.documentName)) {
    selectedDocument = {
      ...updatedDocument,
      documentName: mergedDocumentName(selectedDocument.documentName),
    };
  }
  projectConfigVar({
    ...current,
    selectedDocument,
  });
};

const populateQuotes = ({
  mergeQuotes = false,
  newQuotes,
  existingQuotes,
}: {
  mergeQuotes?: boolean;
  existingQuotes: QuoteProps[];
  newQuotes: QuoteProps[];
}) => {
  if (mergeQuotes) {
    return getUnique<any>(
      [...existingQuotes, ...newQuotes],
      (quote) => quote.id
    );
  }
  return [...newQuotes];
};

const updateProjectInQuote = ({
  existingQuotes,
  project,
}: {
  existingQuotes: QuoteProps[];
  project: ProjectProps;
}) => {
  return existingQuotes.map((quote) =>
    quote.id === project.salesforceQuoteId
      ? {
          ...quote,
          project: {
            ...project,
          },
        }
      : quote
  );
};

export const getGroupedQuotes = (quotes: any[]) => {
  const quotesByProjectStatus: Record<string, any> = groupBy(
    quotes,
    "project.internalStatus"
  );
  const groupedQuotes: Record<string, any> = {};
  Object.values(PROJECT_LIST_STATUS).forEach((status: string) => {
    groupedQuotes[status] = [];
  });
  Object.keys(quotesByProjectStatus).forEach((internalStatus: string) => {
    const statusKey = getProjectStatusFromInternalStatus(internalStatus);
    groupedQuotes[statusKey] = [
      ...groupedQuotes[statusKey],
      ...quotesByProjectStatus[internalStatus],
    ];
  });
  return groupedQuotes;
};

export const updatePopulatedQuotes = ({
  value,
  mergeQuotes,
}: {
  value: QuoteProps[] | ProjectProps;
  mergeQuotes?: boolean;
}) => {
  const current = projectConfigVar();
  let updatedQuotes = [];
  if (value) {
    const { populatedQuotes } = current;
    if (Array.isArray(value)) {
      updatedQuotes = populateQuotes({
        existingQuotes: populatedQuotes,
        newQuotes: value,
        mergeQuotes,
      });
    } else {
      updatedQuotes = updateProjectInQuote({
        existingQuotes: populatedQuotes,
        project: value,
      });
    }
  }
  projectConfigVar({
    ...current,
    populatedQuotes: updatedQuotes,
  });
};

export const setSelectedMaterial = (selectedMaterial: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    selectedMaterial,
  });
};
export const updateEditingProject = (isEditingQuote: boolean) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    isEditingQuote,
  });
};

const processAttachments = (newAttachments: DocumentProps[]) => {
  return newAttachments.map(({ documentName, ...rest }) => {
    const processedName = DocTypesMapper[documentName] || documentName;
    return {
      ...rest,
      documentName: processedName,
    };
  });
};

export const updateProjectAttachments = (
  incomingAttachments: DocumentProps[]
) => {
  const current = projectConfigVar();
  const projectAttachments = processAttachments(incomingAttachments);
  projectConfigVar({
    ...current,
    projectAttachments,
  });
};

export const addToProjectAttachments = (attachment: DocumentProps) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    projectAttachments: [...current.projectAttachments, attachment],
  });
};

export const toggleProductModal = (openProductModal: boolean) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    openProductModal,
  });
};

export const updateActiveConfigStep = (activeConfigStep: number) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    activeConfigStep,
  });
};

interface CraftsManMaterialProp extends MaterialProp {
  alternativeName: string;
  alternativePrice: string;
  alternativeDescription: string;
  material: MaterialProp;
}

export const processCraftmanMaterial = (
  craftsmanMaterial: CraftsManMaterialProp
) => {
  const {
    alternativeName,
    alternativePrice,
    alternativeDescription,
    material,
    ...rest
  } = craftsmanMaterial;
  return {
    ...rest,
    name: alternativeName,
    price: alternativePrice,
    description: alternativeDescription,
    materialId: material?.id,
    categoryId: material?.categoryId,
  };
};

export const useUpsertProject = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback?: () => void;
  onErrorCallback?: (error: any) => void;
}) => {
  const [upsertProject, { loading: upsertProjectLoader }] = useMutation(
    UPSERT_PROJECT,
    {
      onCompleted: (data) => {
        const value = data?.project;
        updatePopulatedQuotes({
          value,
        });
        updateSelectedProject(value);
        onSuccessCallback?.();
      },
      onError: (error) => {
        onErrorCallback?.(error);
      },
    }
  );

  return {
    upsertProject,
    upsertProjectLoader,
  };
};

export const useUpsertAttachment = ({
  onErrorCallback,
  onSuccessCallback,
}: {
  onSuccessCallback?: (attachment: any) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [upsertAttachment, { loading: attachmentLoader }] = useMutation(
    UPSERT_ATTACHMENT,
    {
      onCompleted: (data) => {
        if (data.attachment) {
          onSuccessCallback?.(data.attachment);
        }
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    }
  );

  return {
    upsertAttachment,
    attachmentLoader,
  };
};

export const useUploadFiles = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback: (files: any[]) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [uploadFiles, { loading: uploadFilesLoader }] = useMutation(
    UPLOAD_FILES,
    {
      onCompleted: (data) => {
        if (data?.uploadedFiles) {
          onSuccessCallback(data.uploadedFiles);
        }
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    }
  );

  return {
    uploadFiles,
    uploadFilesLoader,
  };
};

export const useUploadFile = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback: (file: any) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [uploadFile, { loading: uploadFileLoader }] = useMutation(UPLOAD_FILE, {
    onCompleted: (data) => {
      if (data?.uploadedFile) {
        onSuccessCallback(data.uploadedFile);
      }
    },
    onError: (error) => {
      onErrorCallback(error);
    },
  });

  return {
    uploadFile,
    uploadFileLoader,
  };
};

export const getUploadFileName = ({
  location,
  fileName,
  projectId,
}: {
  location: string;
  fileName: string;
  projectId: string;
}) => {
  const env =
    process.env.REACT_NODE_ENV === "development" ? "development" : "production";
  const docType = DOC_MAPPER[location] || location;
  return `${env}_${projectId}_${docType}_${fileName}_${getCurrentTime()}`;
};
