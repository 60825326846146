import { gql, useLazyQuery, useQuery } from "@apollo/client";
import { populateAppointments, updateSelectedAppointment } from "../mutations";

import { AppointmentConfigProps } from "../../pages/types";

const GET_ALL_APPOINTMENTS_WHERE = gql`
  query GetAllAppointments($where: WhereInput!) {
    appointments: getAllAppointments(where: $where) {
      id
      name
      type
      start
      end
      fullDay
      description
      projectId
      quoteId
    }
  }
`;

export const GET_APPOINTMENT_CONFIG = gql`
  query GetSelectedAppointment {
    appointmentConfig @client
  }
`;

const GET_APPOINTMENT_WHERE = gql`
  query GetAppointment($where: WhereInput!) {
    appointment: getAppointment(where: $where) {
      id
      name
      type
      start
      end
      fullDay
      description
      projectId
      quoteId
    }
  }
`;

export const useAppointmentConfig = () => {
  const { data: appointmentData, loading: appointmentConfigLoader } = useQuery<{
    appointmentConfig: AppointmentConfigProps;
  }>(GET_APPOINTMENT_CONFIG);

  return {
    appointmentConfig: appointmentData?.appointmentConfig,
    appointmentConfigLoader,
  };
};

export const useGetAppointmentWhere = () => {
  const [
    getAppointmentWhere,
    { loading: appointmentWhereLoader },
  ] = useLazyQuery(GET_APPOINTMENT_WHERE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => updateSelectedAppointment(data?.appointment),
  });

  return {
    getAppointmentWhere,
    appointmentWhereLoader,
  };
};

export const useGetAllAppointmentsWhere = () => {
  const [
    getAllAppointmentsWhere,
    { loading: appointmentsWhereLoader },
  ] = useLazyQuery(GET_ALL_APPOINTMENTS_WHERE, {
    fetchPolicy: "network-only",
    onCompleted: (data) => populateAppointments(data?.appointments),
    onError: () => {},
  });

  return {
    getAllAppointmentsWhere,
    appointmentsWhereLoader,
  };
};
