import { gql, useQuery } from "@apollo/client";
import { AuthConfigProps } from "../../pages/types";

export const GET_AUTH_CONFIG = gql`
  query GetAuthConfig {
    authConfig @client
  }
`;

export const useAuthConfig = () => {
  const { data: authConfigData, loading: authConfigLoader } = useQuery<{
    authConfig: AuthConfigProps;
  }>(GET_AUTH_CONFIG);
  return {
    authConfig: authConfigData?.authConfig,
    authConfigLoader,
  };
};
