import { FC } from "react";
import { Box, BoxProps } from "@material-ui/core";
import styled from "styled-components/macro";

import { CircularProgress } from "@material-ui/core";

const Root = styled(Box)`
  min-height: 100%;
`;

const Loader: FC<{
  size?: number;
  color?: "secondary" | "primary" | "inherit";
  rootProps?: BoxProps;
}> = ({ size = 40, color = "secondary", rootProps }) => {
  return (
    <Root
      display={"flex"}
      alignContent={"center"}
      justifyContent={"center"}
      {...rootProps}
    >
      <CircularProgress color={color} size={size} />
    </Root>
  );
};

export default Loader;
