import { gql } from "@apollo/client";

export const GET_LEADS = gql`
  query {
    getAllLeads {
      id
      name
      company
      phone
      address {
        city
        country
      }
    }
  }
`;
