import React from "react";
import { Users } from "react-feather";
import async from "../../components/Async";
import AuthGuard from "./guards";

const Leads = async(() => import("../../pages/pages/Leads"));

export const leadsRoutes = {
  id: "Leads",
  path: "/leads",
  icon: <Users />,
  component: Leads,
  children: null,
  guard: AuthGuard,
};
