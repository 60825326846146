import { gql } from "@apollo/client";

export const typeDefs = gql`
  input WhereInput {
    object: Object
    string: String
  }

  type PaginationInput {
    limit: Int
    offset: Int
  }

  type SearchInput {
    searchTerm: String
    paginate: PaginationInput
  }

  type MaterialInput {
    materialId: String
    quantity: Int
    description: String
    price: Float
    totalPrice: Float
  }

  type MaterialDetails {
    id: String
    name: String
    description: String
    price: String
    image: String
    categoryId: String
    dimensions: String
    uom: String
    wholesalerId: String
  }

  type Material {
    material: MaterialDetails
    quantity: Int
    description: String
    price: Float
    totalPrice: Float
  }

  type MaterialCategoryInput {
    categoryName: String
    categoryDescription: String
  }

  type AppointmentInput {
    id: ID
    name: String
    type: String
    start: Date
    end: Date
    fullDay: Boolean
    participant: [ID]
    projectId: ID
    reminder: Boolean
    reminderPeriod: Int
    description: String
    quoteId: ID
  }

  type CraftsmanSignatureInput {
    id: String
    signatureUrl: String
  }

  type CaregiverSignatureInput {
    id: String
    signatureUrl: String
  }

  type SignatureInput {
    craftsman: CraftsmanSignatureInput
    caregiver1: CaregiverSignatureInput
    caregiver2: CaregiverSignatureInput
    caregiver3: CaregiverSignatureInput
  }

  type AttachmentInput {
    id: ID
    projectId: String
    documentName: String
    documentUrl: String
    documentType: String
  }

  type ProjectMaterialInput {
    materialId: String
    quantity: Int
    description: String
    price: Float
    totalPrice: Float
  }

  type Attachment {
    documentName: String
    documentUrl: String
    documentType: String
  }

  type SelectedMaterialInput {
    materialId: String
    quantity: Int
    craftsmanId: String
    craftsmanName: String
    craftsmanDescription: String
    craftsmanPrice: Float
    price: Float
    totalPrice: Float
  }

  type TermsInvoiceInput {
    consent: String
  }

  type FSPInput {
    answer1: Boolean
    answer2: Boolean
    answer3: Boolean
  }

  type RCSInput {
    answer1: Boolean
    answer2: Boolean
    answer3: Boolean
  }

  type AgreementInput {
    accepted: String
    decisionTime: String
  }

  type TermsQuoteInput {
    financialSupportPurpose: [FSPInput]
    receivingCareServices: [RCSInput]
    caregiverQuoteAgreement: AgreementInput
  }

  type InvoiceInput {
    invoiceNumber: Int
    totalPrice: Float
    materials: [SelectedMaterialInput]
    signatures: SignatureInput
  }

  type CaregiverContactInput {
    id: String!
    tenantNumber: String
    insuranceNumber: String
  }

  type ContactInput {
    caregiver1: CaregiverContactInput
    caregiver2: CaregiverContactInput
    caregiver3: CaregiverContactInput
  }

  type InternalQuoteInput {
    validityDate: Date
    totalPrice: Float
    quoteNumber: Int
    propertyManagement: String
    contact: ContactInput
    materials: [SelectedMaterialInput]
    signatures: SignatureInput
    terms: TermsQuoteInput
  }

  type ProjectInput {
    id: ID
    customerNumber: Int
    craftsmanEmail: String
    internalStatus: String
    rejectionReason: String
    projectStartDate: Date
    projectEndDate: Date
    prepayment: Float
    salesforceQuoteId: String
    attachments: [Attachment]
    internalQuote: InternalQuoteInput
    invoice: InvoiceInput
  }

  type WhereInput {
    object: Object
  }

  type CraftsmanMaterialInput {
    id: String
    userId: String
    userEmail: String
    materialId: String
    alternativeName: String
    alternativePrice: Float
    alternativeDescription: String
    favorite: Boolean
  }

  type DeviceInput {
    fcmToken: String
    craftsmanEmail: String
  }

  type Coordinates {
    longitude: String
    latitude: String
    location: String
  }
`;
