import { gql, useSubscription } from "@apollo/client";

export const GET_STREAMS_FEED = gql`
  subscription StreamQuotesFeed {
    streamFeed: streamQuotesFeed {
      eventType
      createdDate
      lastModifiedDate
      status
      craftsman {
        name
        email
      }
    }
  }
`;

export const useStreamFeed = (onSuccessCallback?: (stream: any) => void) => {
  const { data } = useSubscription(GET_STREAMS_FEED, {
    onSubscriptionData: (data) => {
      if (data.subscriptionData.data) {
        onSuccessCallback?.(data.subscriptionData.data);
      }
    },
  });

  return {
    stream: data?.streamFeed,
  };
};
