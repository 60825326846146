import {
  Avatar,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
  Tooltip,
} from "@material-ui/core";
import { Fragment, MouseEvent, useState } from "react";
import { useAppConfig, useAuthConfig } from "../domains/queries";

import { client } from "../domains/client";
import { firebaseActions } from "../firebase";
import i18 from "../i18";
import styled from "styled-components/macro";
import { toggleLanguage } from "../domains/mutations";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

function UserDropdown() {
  const [anchorMenu, setAnchorMenu] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const history = useHistory();
  const { authConfig } = useAuthConfig();
  const { appConfig } = useAppConfig();

  const { user } = { ...authConfig };
  const { appLanguage } = { ...appConfig };
  const { logOut } = firebaseActions;

  const toggleMenu = (e: MouseEvent<HTMLButtonElement>) => {
    setAnchorMenu(e.currentTarget);
  };
  const closeMenu = () => {
    setAnchorMenu(null);
  };
  const handleLogout = async () => {
    await logOut();
    client.clearStore();
    history.push("/auth/sign-in");
  };
  const handleToggleMenu = () => {
    const lang = appLanguage === "en" ? "de" : "en";
    toggleLanguage(lang);
    i18.changeLanguage(lang);
  };

  return (
    <Fragment>
      <Tooltip title="">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
        >
          <Avatar />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        {process.env.NODE_ENV === "development" && (
          <MenuItem onClick={handleToggleMenu}>
            Change language to {appLanguage === "en" ? "German" : "English"}
          </MenuItem>
        )}
        <MenuItem onClick={handleLogout}>
          {user?.email} {t("sign out")}
        </MenuItem>
      </Menu>
    </Fragment>
  );
}

export default UserDropdown;
