import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from "date-fns/locale/de";
import { ThemeProvider } from "styled-components/macro";
import { create } from "jss";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import {
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from "@material-ui/core/styles";
import { SplashScreen } from "@capacitor/splash-screen";
import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from "@capacitor/push-notifications";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import { Capacitor } from "@capacitor/core";
import { useQuery, useMutation } from "@apollo/client";
import { GET_AUTH_CONFIG } from "./domains/queries";
import { UPSERT_DEVICE } from "./domains/mutations";

import createTheme from "./theme";
import Routes from "./routes/Routes";
import { THEMES } from "./constants";

const jss = create({
  ...jssPreset(),
  // @ts-ignore
  insertionPoint: document.getElementById("jss-insertion-point"),
});

function App() {
  const theme = THEMES.DEFAULT;
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { data: auth } = useQuery(GET_AUTH_CONFIG);
  const { authConfig } = auth;
  const [upsertDevice] = useMutation(UPSERT_DEVICE, {
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: "error",
      });
    },
  });

  useEffect(() => {
    SplashScreen.hide();
  }, []);

  useEffect(() => {
    const isPushNotificationsAvailable = Capacitor.isPluginAvailable(
      "PushNotifications"
    );

    if (isPushNotificationsAvailable && authConfig.isSignedIn) {
      PushNotifications.requestPermissions().then((result) => {
        if (result.receive === "granted") {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          enqueueSnackbar(t("Something went wrong"), {
            variant: "error",
          });
        }
      });

      PushNotifications.addListener("registration", (token: Token) => {
        upsertDevice({
          variables: {
            input: {
              fcmToken: token.value,
              craftsmanEmail: authConfig?.user?.email,
            },
          },
        });
      });

      PushNotifications.addListener("registrationError", (error: any) => {
        enqueueSnackbar(t("Error on registration"), {
          variant: "error",
        });
      });

      PushNotifications.addListener(
        "pushNotificationReceived",
        (notification: PushNotificationSchema) => {}
      );

      PushNotifications.addListener(
        "pushNotificationActionPerformed",
        (notification: ActionPerformed) => {}
      );
    }
  }, [enqueueSnackbar, t, upsertDevice, authConfig]);

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Sorgenfrei App"
        defaultTitle="Sorgenfrei App"
      />
      <StylesProvider jss={jss}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
          <MuiThemeProvider theme={createTheme(theme)}>
            <ThemeProvider theme={createTheme(theme)}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  );
}

export default App;
