import { CareGiverProps, MaterialProp } from ".";

import { DOC_LOCATIONS } from "../constants";
import { QuoteFormProps } from "./quotes";

export interface QuoteProps {
  id: string;
  name: string;
  status: string;
  opportunityId?: string;
  createdDate: string;
  lastModifiedDate: string;
  billingAddressFormatted: string;
  phone: string;
  accountId: string;
  caregiver1: CareGiverProps;
  caregiver2: CareGiverProps;
  caregiver3: CareGiverProps;
  project?: any;
}

export interface FilterProps {
  sortByLatest: boolean;
  status: string[];
}

export interface ProjectConfigProps {
  isEditingQuote: boolean;
  selectedProject: Record<string, any>;
  nextCustAndQuote: Record<string, any>;
  currDocLocation: string;
  selectedDocument: Record<string, any>;
  populatedQuotes: QuoteProps[];
  isSearchingQuotes: boolean;
  selectedMaterial: Record<string, any>;
  craftsmanMaterials: MaterialProp[];
  materials: MaterialProp[];
  projectAttachments: any[];
  quoteForm: QuoteFormProps;
  selectedQuoteId?: string;
  selectedQuote: Record<string, any>;
  materialCategories: any[];
  filters: FilterProps;
  materialSearchTerm: string;
  openProductModal: boolean;
  activeConfigStep: number;
  selectedQuoteMarker: any;
  mapData: any;
}

export const projectConfigInitialState: ProjectConfigProps = {
  isEditingQuote: false,
  selectedProject: {},
  nextCustAndQuote: {},
  currDocLocation: DOC_LOCATIONS.Documents,
  selectedDocument: {},
  populatedQuotes: [],
  isSearchingQuotes: false,
  selectedMaterial: {},
  materials: [],
  craftsmanMaterials: [],
  projectAttachments: [],
  quoteForm: {
    customerNumber: undefined,
    quoteNumber: undefined,
    validityDate: null,
    signatures: {
      craftsman: null,
      caregiver1: null,
      caregiver2: null,
      caregiver3: null,
    },
    materials: [],
  },
  selectedQuote: {},
  materialCategories: [],
  filters: {
    sortByLatest: true,
    status: [],
  },
  materialSearchTerm: "",
  openProductModal: false,
  activeConfigStep: 0,
  selectedQuoteMarker: {},
  mapData: {},
};

export interface DocumentProps {
  id: string;
  documentName: string;
  documentUrl: string;
  documentType: string;
}
