import React from "react";
import { Calendar as CalendarIcon } from "react-feather";
import async from "../../components/Async";
import AuthGuard from "./guards";
import { RoutePaths } from "../../pages/constants";

const Calendar = async(() => import("../../pages/pages/Calendar"));

export const calendarRoutes = {
  id: "Calendar",
  path: RoutePaths.Calendar,
  icon: <CalendarIcon />,
  component: Calendar,
  children: null,
  guard: AuthGuard,
};
