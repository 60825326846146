import { projectsRoutes } from "./categories/projectRoutes";
import { authRoutes as catAuthRoutes } from "./categories/authRoutes";
import { landingRoutes as catLandingRoutes } from "./categories/landingRoutes";
import { protectedRoutes as catProtectRoutes } from "./categories/protectedRoutes";
// import { dashboardsRoutes } from "./categories/dashboardRoutes";
import { calendarRoutes } from "./categories/calendarRoutes";
import { settingsRoutes } from "./categories/settingsRoutes";

export const sidebarRoutes = [
  projectsRoutes,
  // dashboardsRoutes,
  calendarRoutes,
  settingsRoutes,
];
export const authRoutes = catAuthRoutes;
export const landingRoutes = catLandingRoutes;
export const protectedRoutes = catProtectRoutes;
