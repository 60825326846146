import { FC } from "react";
import { Button, Box } from "@material-ui/core";
import { Alert, AlertTitle } from "@material-ui/lab";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

interface ErrorDetectionFallbackProps {
  resetError: () => void;
}

export const ErrorDetectionFallback: FC<ErrorDetectionFallbackProps> = ({
  resetError,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    resetError();
    history?.go?.(0);
  };

  return (
    <Box p={8}>
      <Alert
        variant="outlined"
        severity="warning"
        action={
          <Button
            variant="contained"
            color="primary"
            size="small"
            onClick={handleClick}
          >
            {t("Refresh")}
          </Button>
        }
      >
        <AlertTitle>{t("Something went wrong")}</AlertTitle>
        {t(
          "An error has caused the app to crash, click refresh to restore the app"
        )}
      </Alert>
    </Box>
  );
};
