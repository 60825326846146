import React from "react";
import { Sliders } from "react-feather";
import async from "../../components/Async";
import AuthGuard from "./guards";
import { RoutePaths } from "../../pages/constants";

const Default = async(() => import("../../pages/dashboards/Default"));

export const dashboardsRoutes = {
  id: "Dashboard",
  path: RoutePaths.Dashboards,
  icon: <Sliders />,
  component: Default,
  guard: AuthGuard,
};
