import { projectsRoutes } from "./projectRoutes";
import { dashboardsRoutes } from "./dashboardRoutes";
import { calendarRoutes } from "./calendarRoutes";
import { leadsRoutes } from "./leadsRoutes";
import { settingsRoutes } from "./settingsRoutes";

const protectedPageRoutes = {
  id: "Protected",
  children: [
    dashboardsRoutes,
    projectsRoutes,
    leadsRoutes,
    calendarRoutes,
    ...settingsRoutes.children,
  ],
  component: null,
};

export const protectedRoutes = [protectedPageRoutes];
