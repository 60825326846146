import { FC } from "react";

export type AppConfigProps = {
  hasNetworkConnection: boolean;
  sidebarOpen: boolean;
  loaders: Record<string, boolean>;
  modals: Record<string, boolean>;
  account: any;
  activeTabs: {
    project: string;
    calendar: string;
    dashboard: string;
  };
  appLanguage: "en" | "de";
};

export const appConfigInitialState: AppConfigProps = {
  hasNetworkConnection: true,
  sidebarOpen: false,
  loaders: {
    quoteLoader: false,
    projectLoader: false,
  },
  account: {},
  modals: {
    rejectionModal: false,
    appointmentModal: false,
    editAppointmentModal: false,
    showAppointmentModal: false,
    prepaymentModal: false,
    uploadPhotosModal: false,
    documentModal: false,
    editPriceModal: false,
    materialDescriptionModal: false,
    signatureModal: false,
    addMaterialModal: false,
    editMaterialModal: false,
    sketchPad: false,
  },
  activeTabs: {
    project: "",
    calendar: "",
    dashboard: "",
  },
  appLanguage: "de",
};

export interface Option {
  description?: string;
  label: string;
  unavailable?: boolean;
  value: string;
}

export interface PropertyProps {
  content?: string;
  id: string;
  type?: "function";
}

export type ComponentProperties =
  | Record<string, any>
  | string
  | boolean
  | PropertyProps
  | Option[]
  | number;

export interface SingleValidation {
  default?:
    | string
    | boolean
    | number
    | null
    | Array<any>
    | Record<string, string | number | boolean>;
  properties: {
    [key: string]: any;
  };
}

export interface ComponentHeaderProperties {
  order: number;
  component: string;
  layout: string;
  properties?: any;
}

type Conditions = {
  Is: "Is";
  Includes: "Includes";
  GreaterThan: "GreaterThan";
};

export interface ComponentDisplayCondition {
  AND?: boolean;
  when: string;
  hasCondition: keyof Conditions;
  forValue: string | number | boolean | string[];
}

export interface PageComponentProps {
  display?: ComponentDisplayCondition | ComponentDisplayCondition[];
  component?: string;
  components?: any[];
  description?: string;
  title?: string;
  headerProperties?: ComponentHeaderProperties;
  id: string;
  isSearchable?: boolean;
  isHidden?: boolean;
  isContact?: boolean;
  formId?: string;
  versionGroupName?: string;
  layout: string;
  order: number;
  parentId: null | string;
  properties?: Record<string, ComponentProperties>;
  validation?: SingleValidation;
  conditions?: any[];
  materials?: any;
}
export interface NestedPageComponentProps extends PageComponentProps {
  components?: NestedPageComponentProps[];
}

export interface ComponentTreeRendererProps {
  customComponents?: Record<string, FC<any>>;
  customFunctions?: Record<string, (v?: any) => void>;
  customLayoutComponents?: Record<string, FC<any>>;
  config: NestedPageComponentProps;
  globalProps?: Record<string, any>;
  readOnly?: boolean;
}

export interface FormTab {
  id: string;
  name: string;
  category: string;
  order: number;
  components: any[];
  extraProps?: Record<string, any>;
}

export interface ComponentProps {
  id: string;
  formId: string;
  label: string;
  description: string;
  properties?: any;
  validation?: {
    properties?: any;
    default?: any;
  };
}

export interface FormComponentProps {
  config: ComponentProps;
  global: any;
}
