import React from "react";
import styled, { createGlobalStyle } from "styled-components/macro";
import Sidebar from "../components/Sidebar";
import { GlobalStyleProps } from "../pages/types/styles";
import { RouteType } from "../pages/types/routes";

import { spacing } from "@material-ui/system";
import { CssBaseline, Paper as MuiPaper, withWidth } from "@material-ui/core";

const drawerWidth = 258;

const GlobalStyle = createGlobalStyle<GlobalStyleProps>`
  html,
  body,
  #root {
    height: 100%;
  }

  body {
    background: #FFFFFF;
  }

  .MuiCardHeader-action .MuiIconButton-root {
    padding: 4px;
    width: 28px;
    height: 28px;
  }
`;

const Root = styled.div`
  display: flex;
  max-height: 100vh;
  overflow-x: hidden;
  overflow-y: hidden;
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.paper};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

type DashboardPropsType = {
  routes: Array<RouteType>;
  width: "md" | "xs" | "sm" | "lg" | "xl";
};

const Dashboard: React.FC<DashboardPropsType> = ({ children, routes }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Sidebar routes={routes} PaperProps={{ style: { width: drawerWidth } }} />
      <AppContent>
        <MainContent>{children}</MainContent>
      </AppContent>
    </Root>
  );
};

export default withWidth()(Dashboard);
