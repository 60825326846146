import { gql, useLazyQuery } from "@apollo/client";
import { updatePopulatedQuotes, updateSelectedQuote } from "../mutations";
import { PaginationLimit } from "../../constants";
import { useEffect, useState } from "react";
import { translateInternalStatusToProjectStatus } from "pages/constants";
import { isEmpty } from "pages/utils";

const GET_ALL_QUOTES = gql`
  query GetAllQuotes($limit: Int, $offset: Int) {
    allQuotes: getAllQuotes(
      input: { paginate: { limit: $limit, offset: $offset } }
    ) {
      id
      name
      craftsman
      opportunityId
      createdDate
      lastModifiedDate
      coordinates {
        longitude
        latitude
        location
      }
      project {
        internalStatus
        updatedAt
      }
    }
  }
`;

const SEARCH_QUOTES = gql`
  query SearchQuotes($input: SearchInput!) {
    searchQuotes(input: $input) {
      id
      name
      craftsman
      opportunityId
      coordinates {
        longitude
        latitude
        location
      }
      createdDate
      lastModifiedDate
      project {
        internalStatus
        updatedAt
      }
    }
  }
`;

const GET_QUOTE = gql`
  query GetQuote($input: WhereInput!) {
    quote: getQuote(input: $input) {
      id
      name
      craftsman
      opportunityId
      billingAddressFormatted
      createdDate
      lastModifiedDate
      contactPhone
      contactMobile
      ownershipType
      accountId
      coordinates {
        latitude
        longitude
        location
      }
      project {
        id
        internalStatus
        updatedAt
      }
      caregiver1 {
        id
        name
      }
      caregiver2 {
        id
        name
      }
      caregiver3 {
        id
        name
      }
    }
  }
`;

type SearchInput = {
  searchTerm: string;
  paginate: {
    offset: number;
    limit: number;
  };
};

export const useGetSearchQuotes = ({
  onErrorCallback,
}: {
  onErrorCallback?: (error: any) => void;
}) => {
  const [moreQuotesLoader, setMoreQuotesLoader] = useState(false);
  const [
    searchQuotes,
    { loading: searchQuotesLoader, fetchMore },
  ] = useLazyQuery<{
    input: SearchInput;
    searchQuotes: any;
  }>(SEARCH_QUOTES, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      updatePopulatedQuotes({
        value: data.searchQuotes,
      });
    },
    onError: (error) => {
      onErrorCallback?.(error);
    },
  });

  const searchMoreQuotes = async (
    offset = 0,
    limit = PaginationLimit.quotes
  ) => {
    setMoreQuotesLoader(true);
    await fetchMore({
      variables: {
        limit,
        offset,
      },
    }).then((results) => {
      updatePopulatedQuotes({
        value: results.data?.searchQuotes,
        mergeQuotes: true,
      });
    });
    setMoreQuotesLoader(false);
  };

  const handleSearchQuotes = async (searchTerm: string) => {
    await searchQuotes({
      variables: {
        input: {
          searchTerm,
          paginate: {
            limit: PaginationLimit.quotes,
            offset: 0,
          },
        },
      },
    });
  };

  return {
    handleSearchQuotes,
    searchMoreQuotes,
    searchQuotesLoader: searchQuotesLoader,
    searchMoreQuotesLoader: moreQuotesLoader,
  };
};

export const useGetAllQuotes = () => {
  const [moreQuotesLoader, setMoreQuotesLoader] = useState(false);
  const [getAllQuotes, { fetchMore, loading: allQuotesLoader }] = useLazyQuery(
    GET_ALL_QUOTES,
    {
      fetchPolicy: "no-cache",
      onCompleted: (data) => {
        updatePopulatedQuotes({
          value: data.allQuotes,
        });
      },
    }
  );

  const fetchMoreQuotes = async (
    offset = 0,
    limit = PaginationLimit.quotes
  ) => {
    setMoreQuotesLoader(true);
    await fetchMore({
      variables: {
        limit: limit,
        offset,
      },
    }).then((results) => {
      updatePopulatedQuotes({
        value: results.data?.allQuotes,
        mergeQuotes: true,
      });
    });

    setMoreQuotesLoader(false);
  };

  const fetchAllQuotes = async () => {
    await getAllQuotes({
      variables: {
        limit: PaginationLimit.quotes,
        offset: 0,
      },
    });
  };

  return {
    fetchAllQuotes,
    fetchMoreQuotes,
    allQuotesLoader: allQuotesLoader,
    fetchmoreQuotesLoader: moreQuotesLoader,
  };
};

export const useGetQuote = () => {
  const [getQuote, { loading: quoteLoader }] = useLazyQuery(GET_QUOTE, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      updateSelectedQuote(data?.quote);
    },
  });

  return {
    getQuote,
    quoteLoader,
  };
};

export const useApplyFilterToQuotes = (
  filters: any,
  populatedQuotes: any[]
) => {
  const [quotes, setQuotes] = useState<any[]>([]);
  const internalStatus = translateInternalStatusToProjectStatus();
  useEffect(() => {
    const sortQuotes = (quotes: any[]) => {
      const clonedQuotes = [...quotes];
      return clonedQuotes.sort((a: any, b: any) => {
        const aTime = Date.parse(a?.project?.updatedAt || a?.lastModifiedDate);
        const bTime = Date.parse(b?.project?.updatedAt || b?.lastModifiedDate);
        return filters.sortByLatest ? bTime - aTime : aTime - bTime;
      });
    };

    const filterByStatus = (quotes: any[]) => {
      if (!isEmpty(filters.status)) {
        return quotes.filter((quote) => {
          const status = internalStatus[quote.project?.internalStatus || "new"];
          return filters.status.includes(status);
        });
      }
      return quotes;
    };
    if (populatedQuotes) {
      const filteredQuotes = filterByStatus(populatedQuotes);
      const sortedQuotes = sortQuotes(filteredQuotes);
      setQuotes(sortedQuotes);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [populatedQuotes, filters]);
  return { quotes };
};
