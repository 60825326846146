import { isEmpty } from "../utils";

export const TAB_LABELS = {
  Overview: "Overview",
  Quote: "Quote",
  Documentation: "Documentation",
};
export const DASHBOARD_TABS = ["This week", "This month", "Select period"];

export enum RoutePaths {
  Projects = "/projects",
  Dashboards = "/dashboard",
  Calendar = "/calendar",
  Settings = "/settings",
  SettingsCompany = "/settings/company",
  SettingsMaterials = "/settings/materials",
  SettingsTasks = "/settings/tasks",
  Auth = "/auth",
}
export const CURRENCY = "€";
export enum Deductables {
  MwSt = 19,
  DeductingInsuranceFund = 0,
}
export enum STATUS {
  New = "New",
  InProgress = "",
  ConstructionEnd = "ClosedWon",
  Rejected = "Escalated",
  Accepted = "Accepted",
  ProjectLost = "ClosedLost",
}
export const APPOINTMENT_TYPES = [
  {
    value: "onSiteVisit",
    label: "Onsite Visit",
  },
  {
    value: "constructionStart",
    label: "Construction Start",
  },
];
export const editorInitialValue: any[] = [
  {
    type: "paragraph",
    children: [
      {
        text: "",
      },
    ],
  },
];
export const DocTypesMapper: Record<string, string> = {
  quote: "Quote PDF",
  invoice: "Invoice PDF",
  prepayment: "Prepayment PDF",
  checkup: "Checkup PDF",
  assignment: "Assignment PDF",
  insurance: "Insurance PDF",
  tenant: "Tenant PDF",
  "craftsman-assignment": "Craftsman Assignment PDF",
};

export enum DOC_LOCATIONS {
  Documents = "Documents",
  Photos = "Construction Photos",
  PhotosBefore = "Before Construction Photos",
  PhotosAfter = "After Construction Photos",
  Sketches = "Sketches",
}

export enum INTERNAL_PROJECT_STATUS {
  Reject = "reject",
  New = "new",
  NewQuotation = "progress_new_quotation",
  OpenQuotation = "progress_open_quotation",
  CompleteQuotation = "progress_complete_quotation",
  CustomerReview = "progress_in_customer_review",
  InsuranceReview = "progress_in_insurance_review",
  ConstructionStart = "progress_construction_start",
  ConstructionEnd = "progress_construction_end",
  ConstructionLog = "progress_construction_log",
  ConstructionApproval = "progress_construction_approval",
  Done = "done",
}

export enum AllowedMaterialType {
  Fundable = "fundable",
  Extra = "extra",
}

export const PROJECT_LIST_STATUS = {
  New: "New",
  QuoteCreation: "Quote Creation",
  WaitingForSorgenfrei: "Waiting for Sorgenfrei",
  WaitingForInsurance: "Waiting for Insurance",
  WaitingForCustomer: "Waiting for Customer",
  Construction: "Construction",
  CheckUp: "Checkup",
  Done: "Done",
  Rejected: "Rejected",
};

export const DateFormatting = "dd.MM.yyyy";

export enum ConstructionDocs {
  BeforeConstruction = "beforeConstruction",
  AfterConstruction = "afterConstruction",
}

export const DOC_MAPPER: Record<string, string> = {
  [DOC_LOCATIONS.PhotosBefore]: ConstructionDocs.BeforeConstruction,
  [DOC_LOCATIONS.PhotosAfter]: ConstructionDocs.AfterConstruction,
};

export enum DocumentTypes {
  Photo = "photo",
  Sketch = "sketch",
  Pdf = "pdf",
}

export const allowedActionsFromStatus = ({
  status,
  hasNetworkConnection = true,
  isEditingQuote = false,
}: {
  status?: INTERNAL_PROJECT_STATUS;
  hasNetworkConnection?: boolean;
  isEditingQuote?: boolean;
}) => {
  const actions = {
    showSignatureModal: false,
    isInvoice: false,
    isQuote: false,
    canModifyQuoteTabDetails: false,
    canEditQuote: false,
    isQuoteOrInvoiceTabReadonly: false,
    hasPreConstructionDocs: false,
    hasPostConstructionDocs: false,
    showDownloadPostConstructionDocs: false,
  };
  if (status) {
    actions.showSignatureModal = [
      INTERNAL_PROJECT_STATUS.OpenQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
    ].includes(status);
    actions.isInvoice = [
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
      INTERNAL_PROJECT_STATUS.Done,
    ].includes(status);
    actions.canModifyQuoteTabDetails = [
      INTERNAL_PROJECT_STATUS.NewQuotation,
      INTERNAL_PROJECT_STATUS.OpenQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
    ].includes(status);
    actions.isQuoteOrInvoiceTabReadonly = ![
      INTERNAL_PROJECT_STATUS.NewQuotation,
      INTERNAL_PROJECT_STATUS.OpenQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
    ].includes(status);
    actions.canEditQuote = [
      INTERNAL_PROJECT_STATUS.CompleteQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionStart,
      INTERNAL_PROJECT_STATUS.ConstructionEnd,
    ].includes(status);
    actions.hasPostConstructionDocs = [
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
      INTERNAL_PROJECT_STATUS.Done,
    ].includes(status);
    actions.showDownloadPostConstructionDocs = [
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
      INTERNAL_PROJECT_STATUS.Done,
    ].includes(status);
    actions.hasPreConstructionDocs = [
      INTERNAL_PROJECT_STATUS.CompleteQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionStart,
      INTERNAL_PROJECT_STATUS.ConstructionEnd,
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
      INTERNAL_PROJECT_STATUS.Done,
    ].includes(status);
    actions.isQuote = [
      INTERNAL_PROJECT_STATUS.NewQuotation,
      INTERNAL_PROJECT_STATUS.OpenQuotation,
    ].includes(status);
  }

  if (!hasNetworkConnection && status) {
    actions.showSignatureModal = [
      INTERNAL_PROJECT_STATUS.NewQuotation,
      INTERNAL_PROJECT_STATUS.ConstructionLog,
    ].includes(status);
  }
  if (isEditingQuote) {
    actions.isQuoteOrInvoiceTabReadonly = false;
    actions.showSignatureModal = true;
  }
  return actions;
};

export const translateProjectToInternalStatus = () => {
  const status = {
    [PROJECT_LIST_STATUS.New]: [INTERNAL_PROJECT_STATUS.New],
    [PROJECT_LIST_STATUS.QuoteCreation]: [
      INTERNAL_PROJECT_STATUS.NewQuotation,
      INTERNAL_PROJECT_STATUS.OpenQuotation,
    ],
    [PROJECT_LIST_STATUS.WaitingForCustomer]: [
      INTERNAL_PROJECT_STATUS.CustomerReview,
    ],
    [PROJECT_LIST_STATUS.WaitingForSorgenfrei]: [
      INTERNAL_PROJECT_STATUS.CompleteQuotation,
    ],
    [PROJECT_LIST_STATUS.WaitingForInsurance]: [
      INTERNAL_PROJECT_STATUS.InsuranceReview,
    ],
    [PROJECT_LIST_STATUS.Construction]: [
      INTERNAL_PROJECT_STATUS.ConstructionStart,
      INTERNAL_PROJECT_STATUS.ConstructionEnd,
    ],
    [PROJECT_LIST_STATUS.CheckUp]: [
      INTERNAL_PROJECT_STATUS.ConstructionLog,
      INTERNAL_PROJECT_STATUS.ConstructionApproval,
    ],

    [PROJECT_LIST_STATUS.Done]: [INTERNAL_PROJECT_STATUS.Done],
    [PROJECT_LIST_STATUS.Rejected]: [INTERNAL_PROJECT_STATUS.Reject],
  };
  return status;
};

export const translateInternalStatusToProjectStatus = () => {
  let internalStatus: Record<string, string> = {};
  const projectStatus = translateProjectToInternalStatus();
  Object.keys(projectStatus).forEach((key) => {
    let status = {};
    projectStatus[key].forEach((s) => {
      status = {
        ...status,
        [s]: key,
      };
    });
    internalStatus = {
      ...internalStatus,
      ...status,
    };
  });
  return internalStatus;
};

export const getProjectStatus = () => {
  const projectStatus = translateProjectToInternalStatus();
  return Object.keys(projectStatus).map((key) => ({
    label: key,
    statuses: projectStatus[key],
  }));
};

export const getProjectStatusFromInternalStatus = (status: string) => {
  const foundStatus = getProjectStatus().find((s) =>
    s.statuses.includes(status as INTERNAL_PROJECT_STATUS)
  );
  return foundStatus?.label || PROJECT_LIST_STATUS.New;
};

interface ShouldTabBeDisabledProps {
  status?: INTERNAL_PROJECT_STATUS;
  tabLabel: string;
  selectedAppointment: any;
}

export const shouldTabBeDisabled = ({
  status,
  tabLabel,
  selectedAppointment,
}: ShouldTabBeDisabledProps) => {
  const internalStatus = status || INTERNAL_PROJECT_STATUS.New;
  const isOverviewTab = tabLabel === TAB_LABELS.Overview;
  const isQuoteTab = tabLabel === TAB_LABELS.Quote;
  const isNewOrRejected = [
    INTERNAL_PROJECT_STATUS.New,
    INTERNAL_PROJECT_STATUS.Reject,
  ].includes(internalStatus);
  switch (true) {
    case isOverviewTab:
      return false;
    case isQuoteTab && internalStatus === INTERNAL_PROJECT_STATUS.NewQuotation:
      return isEmpty(selectedAppointment);
    case isNewOrRejected:
      return true;
    default:
      return false;
  }
};
