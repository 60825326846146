import { blue, deepOrange, green, orange } from "@material-ui/core/colors";
import { format, setHours, setMinutes, setSeconds } from "date-fns";
import { isIOS, isMacOs } from "react-device-detect";

import { editorInitialValue } from "../constants";

const isInvalidString = (str: string) => !str || str.length <= 0;

export const getCurrentTime = () => format(new Date(), "dd-MM-yyyy-HH:mm:ss");
export const getDate = (value: string) => format(new Date(value), "dd.MM.yyyy");
export const getTime = (value: string, timeFormat = "dd.MM.yyyy HH:mm") =>
  format(new Date(value), timeFormat);

export const getDateTime = (value: string) => {
  if (isInvalidString(value)) return "";
  const date = getDate(value);
  const time = getTime(value);
  return `${date} - ${time}`;
};

export const getPlatformMap = (addr: string) => {
  const encodedAddr = encodeURIComponent(addr);
  if (isIOS || isMacOs) {
    return `https://maps.apple.com/?q=${encodedAddr}`;
  }

  return `https://www.google.com/maps/search/?api=1&query=${encodedAddr}`;
};

export const getNumberPrefix = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getStatusColors = (status: string) => {
  const cHex = 50,
    bgHex = 900;
  switch (status.toLowerCase()) {
    case "new":
      return {
        backgroundColor: blue[cHex],
        color: blue[bgHex],
      };
    case "closedwon":
      return {
        backgroundColor: green[cHex],
        color: green[bgHex],
      };
    case "closedlost":
      return {
        backgroundColor: deepOrange[cHex],
        color: deepOrange[bgHex],
      };
    default:
      break;
  }
  return {
    backgroundColor: orange[cHex],
    color: orange[bgHex],
  };
};

export const a11yProps = (index: number, component: string) => ({
  id: `tab-{component}-${index}`,
  "aria-controls": `${component}-tabpanel-${index}`,
});

export const tryToParse = (value: any, fallback: any = null) => {
  try {
    return JSON.parse(value);
  } catch {
    return fallback;
  }
};

export const getEditorValue = (value?: string) =>
  tryToParse(value, editorInitialValue) || editorInitialValue;

export const getInitials = (value?: string) =>
  value
    ?.split(" ")
    .map((word) => word.charAt(0).toUpperCase())
    .join("") || "";

export const getFirstNameIntialAndLastName = (value?: string) => {
  if (!value) return value;
  const [firstName, lastName] = value.split(" ");
  const firstNameInitial = firstName && firstName.charAt(0).toUpperCase();
  return `${firstNameInitial}. ${lastName ? lastName : ""}`;
};

export const getDateInWorkingHours = (
  date: string | Date,
  timePos: "start" | "end"
) => {
  const hours = timePos === "start" ? 9 : 17;
  return setSeconds(
    new Date(setMinutes(new Date(setHours(new Date(date), hours)), 0)),
    0
  ).toISOString();
};

export const projectStatusMapper = (status?: string) => {
  switch (status) {
    case "done":
      return "Done";
    case "reject":
      return "Rejected";
    case "new":
      return "New";
    default:
      return "In Progress";
  }
};

export const isEmpty = (val: any) => {
  if (!val) return true;
  if (typeof val === "object" && !Array.isArray(val)) {
    return Object.keys(val).length <= 0;
  }
  return val.length <= 0;
};
export const deepClone = <T>(val: T): T => JSON.parse(JSON.stringify(val));

const omitRecursively = (value: any, cb: (val: any) => boolean) => {
  Object.entries(value).forEach(([k, v]) => {
    if (v && typeof v === "object") {
      omitRecursively(v, cb);
    }
    if (cb(v)) {
      delete value[k];
    }
  });
  return value;
};

export const omitBy = (
  value: Record<string, any>,
  callbackFunc: (val: any) => boolean
) => {
  const clonedValues = deepClone(value);
  return omitRecursively(clonedValues, callbackFunc);
};

export const getValueFromPath = (v: string, o: any) => {
  let value: any = { ...o };
  v.split(".").forEach((k) => {
    if (value) {
      value = value[k];
    } else {
      value = undefined;
    }
  });
  return value;
};

export const groupBy = (items: any[] = [], value: string) =>
  items.reduce((result, item) => {
    let key = getValueFromPath(value, item);
    return {
      ...result,
      [key]: [...(result[key] || []), item],
    };
  }, {});

export const debounce = <T extends (...args: any[]) => void>(
  fn: T,
  ms = 300
) => {
  let timeoutId: ReturnType<typeof setTimeout>;
  return function (this: any, ...args: any[]) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => fn.apply(this, args), ms);
  } as T;
};

const isObject = (obj: any) => obj && typeof obj === "object";

export const deepMerge = (old: any, recent: any) => {
  const clonedOld = deepClone(old);
  const clonedRecent = deepClone(recent);

  if (!isObject(clonedOld) || !isObject(clonedRecent)) return clonedOld;

  Object.keys(clonedOld).forEach((key) => {
    const oldValue = clonedOld[key];
    const recentValue = clonedRecent[key];

    if (isObject(oldValue) && isObject(recentValue)) {
      clonedRecent[key] = deepMerge(oldValue, recentValue);
    } else if (oldValue && !recentValue) {
      clonedRecent[key] = oldValue;
    }
  });
  return clonedRecent;
};

export const getUnique = <T>(values: T[], callback: (arg: T) => keyof T) => {
  let valueIds: Record<keyof T, boolean>;
  values.forEach((value) => {
    const id = callback(value);
    valueIds = {
      ...valueIds,
      [id]: false,
    };
  });

  const uniqueValues: T[] = [];

  values.forEach((value) => {
    const id = callback(value);
    if (!valueIds[id]) {
      valueIds[id] = true;
      uniqueValues.push(value);
    }
  });

  return uniqueValues;
};

export const isNil = (value: any) => {
  return value === undefined || value === null;
};

export const urlToFile = async (
  url: string,
  filename: string,
  mimeType?: string
) => {
  const type = (mimeType = mimeType || (url.match(/^data:([^;]+);/) || "")[1]);
  const response = await fetch(url);
  const buffer = await response.arrayBuffer();
  return new File([buffer], filename, { type });
};

export const sortBy = (values: any[], key: string) => {
  return values.sort((a, b) => Number(a[key]) - Number(b[key]));
};

export const isNumber = (value: number | string | undefined) => {
  return Number.isFinite(Number(value));
};

export const capitalize = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1);
};

export const isFinite = (value: string | number) => {
  return Number.isFinite(Number(value));
};
