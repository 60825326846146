import { ApolloClient, split, ApolloLink } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { cache } from "./cache";
import { typeDefs } from "./types";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { createUploadLink } from "apollo-upload-client";
import firebase from "firebase/app";

const getAuthToken = async () => {
  const currentUser = await firebase.auth().currentUser;
  return currentUser?.email;
};

const authLink = setContext(async (_, { headers }) => {
  const token = await getAuthToken();
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token || null}`,
    },
  };
});

const webSocketLink = new WebSocketLink({
  uri: process.env.REACT_APP_BE_SUBSCRIPTION_URL ?? "",
  options: {
    reconnect: true,
    reconnectionAttempts: 50,
    connectionParams: async () => {
      const token = await getAuthToken();
      return {
        headers: {
          authorization: `Bearer ${token || null}`,
        },
      };
    },
    timeout: 20000,
    lazy: true,
  },
});

const httpLink = createUploadLink({
  uri: process.env.REACT_APP_BE_URL,
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  webSocketLink,
  authLink.concat((httpLink as unknown) as ApolloLink)
);

export const client = new ApolloClient({
  cache,
  link: splitLink,
  typeDefs,
  connectToDevTools: process.env.NODE_ENV !== "production",
});
