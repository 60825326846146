import { gql, useLazyQuery, useQuery } from "@apollo/client";

import { DocumentTypes } from "pages/constants";
import { ProjectConfigProps } from "../../pages/types";
import { updateSelectedProject } from "domains/mutations";

export const GET_PROJECT_CONFIG = gql`
  query GetProjectConfig {
    projectConfig @client
  }
`;

const GET_PROJECT = gql`
  query GetProject($where: WhereInput!) {
    project: getProject(where: $where) {
      id
      customerNumber
      craftsmanEmail
      internalStatus
      prepayment
      salesforceQuoteId
      projectStartDate
      projectEndDate
      projectRevenue
      internalApproved
      wizardMaterials
      invoice {
        invoiceNumber
        totalPrice
        defects
        materials {
          quantity
          price
          material {
            id
            name
            description
          }
          craftsmanDescription
          craftsmanId
          craftsmanName
          craftsmanPrice
        }
        signatures {
          craftsman {
            id
            signatureUrl
          }
          caregiver1 {
            id
            signatureUrl
          }
          caregiver2 {
            id
            signatureUrl
          }
          caregiver3 {
            id
            signatureUrl
          }
        }
      }
      internalQuote {
        quoteNumber
        validityDate
        totalPrice
        materials {
          quantity
          price
          material {
            id
            name
            description
          }
          craftsmanDescription
          craftsmanId
          craftsmanName
          craftsmanPrice
        }
        contact {
          caregiver1 {
            id
            tenantNumber
            insuranceNumber
          }
          caregiver2 {
            id
            tenantNumber
            insuranceNumber
          }
          caregiver3 {
            id
            tenantNumber
            insuranceNumber
          }
        }
        terms {
          financialSupportPurpose {
            answer1
            answer2
            answer3
          }
          receivingCareServices {
            answer1
            answer2
            answer3
          }
          caregiverQuoteAgreement {
            accepted
            decisionTime
          }
        }
        signatures {
          craftsman {
            id
            signatureUrl
          }
          caregiver1 {
            id
            signatureUrl
          }
          caregiver2 {
            id
            signatureUrl
          }
          caregiver3 {
            id
            signatureUrl
          }
        }
        contact {
          caregiver1 {
            id
            tenantNumber
            insuranceNumber
          }
        }
      }
    }
  }
`;

export const GET_NEXT_CUSTOMER_AND_QUOTE_NO = gql`
  query NextQuoteAndCustomerNumber($craftsmanEmail: String!) {
    nextCustAndQuoteNo: nextQuoteAndCustomerNumber(
      craftsmanEmail: $craftsmanEmail
    ) {
      customerNumber
      quoteNumber
    }
  }
`;

export const GET_NEXT_CUSTOMER_AND_INVOICE_NO = gql`
  query NextInvoiceAndCustomerNumber($craftsmanEmail: String!) {
    nextInvoiceNo: nextInvoiceAndCustomerNumber(
      craftsmanEmail: $craftsmanEmail
    ) {
      invoiceNumber
    }
  }
`;

export const GET_PROJECT_PDF = gql`
  query GetProjectPdf($id: ID!, $type: String!) {
    projectPdf: getProjectPdf(id: $id, type: $type) {
      documentUrl
      documentType
    }
  }
`;

export const GET_ALL_ATTACHMENTS = gql`
  query GetAllAttachments($where: WhereInput!) {
    projectAttachments: getAllAttachments(where: $where) {
      documentUrl
      documentName
      documentType
    }
  }
`;

export const GET_ATTACHMENT = gql`
  query GetAttachment($where: WhereInput!) {
    projectAttachment: getAttachment(where: $where) {
      documentUrl
      documentName
      documentType
    }
  }
`;

export const GET_DOCUMENT_ATTACHMENTS = gql`
  query GetAllAttachments($where: WhereInput!) {
    projectAttachments: getAllAttachments(where: $where) {
      id
      documentName
      documentType
    }
  }
`;

export const GET_MERGED_PDF = gql`
  query GetMergedPdf($id: ID!, $type: String!) {
    mergedPdf: getMergedProjectPdf(id: $id, type: $type) {
      documentUrl
      documentName
      documentType
    }
  }
`;

export const useProjectConfig = () => {
  const { data: projectConfigData, loading: projectConfigLoader } = useQuery<{
    projectConfig: ProjectConfigProps;
  }>(GET_PROJECT_CONFIG);
  return {
    projectConfig: projectConfigData?.projectConfig,
    projectConfigLoader,
  };
};

export const useGetProject = () => {
  const [getProject, { loading: projectLoader }] = useLazyQuery(GET_PROJECT, {
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {
      updateSelectedProject(data?.project);
    },
  });

  return {
    getProject,
    projectLoader,
  };
};

export const useGetAllAttachments = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback: (projectAttachments: any[]) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [getAllAttachments, { loading: allAttachmentsLoader }] = useLazyQuery(
    GET_ALL_ATTACHMENTS,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data?.projectAttachments) {
          onSuccessCallback(data.projectAttachments);
        }
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    }
  );

  return {
    getAllAttachments,
    allAttachmentsLoader,
  };
};

export const useGetDocumentAttachments = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback: (projectAttachments: any[]) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [
    getDocumentAttachmentsAsync,
    { loading: allAttachmentsLoader },
  ] = useLazyQuery(GET_DOCUMENT_ATTACHMENTS, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      if (data?.projectAttachments) {
        onSuccessCallback(data.projectAttachments);
      }
    },
    onError: (error) => {
      onErrorCallback(error);
    },
  });

  const getDocumentAttachments = (projectId: string) =>
    getDocumentAttachmentsAsync({
      variables: {
        where: {
          object: {
            documentType: DocumentTypes.Pdf,
            projectId,
          },
        },
      },
    });

  return {
    getDocumentAttachments,
    allAttachmentsLoader,
  };
};

export const useGetAttachment = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback: (projectAttachment: any) => void;
  onErrorCallback: (error: any) => void;
}) => {
  const [getAttachmentAsync, { loading: getAttachmentLoader }] = useLazyQuery(
    GET_ATTACHMENT,
    {
      fetchPolicy: "network-only",
      onCompleted: (data) => {
        if (data?.projectAttachment) {
          onSuccessCallback(data.projectAttachment);
        }
      },
      onError: (error) => {
        onErrorCallback(error);
      },
    }
  );

  const getAttachment = (attachmentId: string) =>
    getAttachmentAsync({
      variables: {
        where: {
          object: {
            id: attachmentId,
          },
        },
      },
    });

  return {
    getAttachment,
    getAttachmentLoader,
  };
};
