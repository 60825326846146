import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";

import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/client";
import "@capacitor/core";
import { SplashScreen } from "@capacitor/splash-screen";
import * as Sentry from "@sentry/capacitor";
import * as SentryReact from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import App from "./App";
import "./mocks";
import "./i18";
import Loader from "./components/Loader";
import { client } from "./domains/client";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { SnackbarProvider } from "notistack";
import { FirebaseProvider } from "./firebase";
import { ErrorDetectionFallback } from "./components/ErrorDetectionFallback";

Sentry.init(
  {
    dsn: process.env.SENTRY_DSN,
    dist: "1",
    release: "azimio@1.0.0",
  },
  () =>
    SentryReact.init({
      integrations: [new Integrations.BrowserTracing()],
      /*Value needs to be adjusted in prod, currently capturing 100% of transactions*/
      tracesSampleRate: 1.0,
    })
);

SplashScreen.show({
  autoHide: false,
});

ReactDOM.render(
  <FirebaseProvider>
    <ApolloProvider client={client}>
      <Suspense fallback={<Loader />}>
        <SnackbarProvider maxSnack={3}>
          <SentryReact.ErrorBoundary
            fallback={({ resetError }) => (
              <ErrorDetectionFallback resetError={resetError} />
            )}
            showDialog
          >
            <App />
          </SentryReact.ErrorBoundary>
        </SnackbarProvider>
      </Suspense>
    </ApolloProvider>
  </FirebaseProvider>,
  document.getElementById("root")
);

serviceWorkerRegistration.register();
