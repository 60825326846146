import React from "react";
import { Users } from "react-feather";
import async from "../../components/Async";
import NoAuthGuard from "../../components/NoAuthGuard";
import { RoutePaths } from "../../pages/constants";

const SignIn = async(() => import("../../pages/auth/SignIn"));
const ResetPassword = async(() => import("../../pages/auth/ResetPassword"));
const Page404 = async(() => import("../../pages/auth/Page404"));
const Page500 = async(() => import("../../pages/auth/Page500"));

const authPageRoutes = {
  id: "Auth",
  path: RoutePaths.Auth,
  icon: <Users />,
  children: [
    {
      path: "/auth/sign-in",
      name: "Sign In",
      component: SignIn,
      guard: NoAuthGuard,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
  component: null,
};

export const authRoutes = [authPageRoutes];
