import { makeVar } from "@apollo/client";
import {
  AppointmentConfigProps,
  AppConfigProps,
  ProjectConfigProps,
  AuthConfigProps,
  appConfigInitialState,
  authConfigInitialState,
  projectConfigInitialState,
  appointmentConfigInitialState,
  OpportunityConfigProps,
  opportunityConfigInitialState,
} from "../pages/types";

export const appConfigVar = makeVar<AppConfigProps>({
  ...appConfigInitialState,
});
export const appointmentConfigVar = makeVar<AppointmentConfigProps>({
  ...appointmentConfigInitialState,
});
export const projectConfigVar = makeVar<ProjectConfigProps>({
  ...projectConfigInitialState,
});
export const authConfigVar = makeVar<AuthConfigProps>({
  ...authConfigInitialState,
});
export const opportunityConfigVar = makeVar<OpportunityConfigProps>({
  ...opportunityConfigInitialState,
});

export const LocalQueries = {
  fields: {
    appConfig: {
      read() {
        return appConfigVar();
      },
    },
    appointmentConfig: {
      read() {
        return appointmentConfigVar();
      },
    },
    projectConfig: {
      read() {
        return projectConfigVar();
      },
    },
    authConfig: {
      read() {
        return authConfigVar();
      },
    },
    opportunityConfig: {
      read() {
        return opportunityConfigVar();
      },
    },
  },
};
