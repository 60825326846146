import {
  getVisibleMaterials,
  populateCraftsmanMaterials,
  populateMaterialCategories,
  populateMaterials,
  removeDuplicateMaterials,
} from "domains/mutations";
import { gql, useLazyQuery } from "@apollo/client";
import { useEffect, useState } from "react";

import { isEmpty } from "pages/utils";

const GET_MATERIALS = gql`
  query GetAllMaterials {
    materials: getAllMaterials {
      id
      price
      name
      categoryId
      description
      type
      editable
      hidden
      position
    }
  }
`;

export const GET_MATERIAL_CATEGORIES = gql`
  query GetMaterialCategories {
    materialCategories: getMaterialCategories {
      id
      categoryName
      categoryDescription
    }
  }
`;

export const GET_CRAFTSMAN_MATERIALS = gql`
  query GetCraftsmanMaterials {
    craftsmanMaterials: getCraftsmanMaterials {
      id
      material {
        id
        categoryId
      }
      alternativeName
      alternativePrice
      alternativeDescription
      favorite
      position
    }
  }
`;

const SEARCH_MATERIALS = gql`
  query Search($input: MongoSearchInput!) {
    search(input: $input) {
      materials {
        id
        price
        name
        categoryId
        description
        type
        editable
        hidden
      }
    }
  }
`;

export const useGetMaterials = () => {
  const [getMaterials, { loading: materialsLoader }] = useLazyQuery(
    GET_MATERIALS,
    {
      onCompleted: (data) => {
        if (data?.materials) {
          populateMaterials(data.materials);
        }
      },
    }
  );

  return {
    getMaterials,
    materialsLoader,
  };
};

export const useGetCraftsmanMaterials = () => {
  const [
    getCraftsmanMaterials,
    { loading: craftsManMaterialsLoader },
  ] = useLazyQuery(GET_CRAFTSMAN_MATERIALS, {
    onCompleted: (data) => populateCraftsmanMaterials(data?.craftsmanMaterials),
  });

  return {
    getCraftsmanMaterials,
    craftsManMaterialsLoader,
  };
};

export const useGetMaterialCategories = () => {
  const [
    getMaterialCategories,
    { loading: materialCategoriesLoader },
  ] = useLazyQuery(GET_MATERIAL_CATEGORIES, {
    onCompleted: (data) => populateMaterialCategories(data?.materialCategories),
  });

  return {
    getMaterialCategories,
    materialCategoriesLoader,
  };
};

export const useGetSearchMaterials = ({
  onErrorCallback,
}: {
  onErrorCallback?: (error: any) => void;
}) => {
  const [moreMaterialsLoader, setMoreMaterialsLoader] = useState(false);
  const [
    searchMaterials,
    { loading: searchMaterialsLoader, fetchMore },
  ] = useLazyQuery(SEARCH_MATERIALS, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {},
    onError: (error) => {
      onErrorCallback?.(error);
    },
  });

  const searchMoreMaterials = async (offset: number = 0) => {
    setMoreMaterialsLoader(true);
    await fetchMore({
      variables: {
        offset,
      },
    }).then((results) => {});
    setMoreMaterialsLoader(false);
  };

  const handleSearchMaterials = async (searchTerm: string) => {
    await searchMaterials({
      variables: {
        input: {
          searchTerm,
        },
      },
    });
  };

  return {
    handleSearchMaterials,
    searchMoreMaterials,
    searchMaterialsLoader: searchMaterialsLoader,
    searchMoreMaterialsLoader: moreMaterialsLoader,
  };
};

export const useApplyFilterToMaterials = ({
  searchTerm,
  originalMaterials,
  craftsmanMaterials,
  selectedMaterials,
}: {
  searchTerm: string;
  craftsmanMaterials: any[];
  originalMaterials: any[];
  selectedMaterials?: any[];
}) => {
  const [availableMaterials, setAvailableMaterials] = useState<any[]>([]);

  useEffect(() => {
    const nonDupMaterials = removeDuplicateMaterials(
      craftsmanMaterials,
      originalMaterials
    );
    const populatedMaterials = getVisibleMaterials(
      nonDupMaterials,
      selectedMaterials
    );

    const sortMaterials = (materials: any[]) => {
      const clonedMaterials = [...materials];
      return clonedMaterials.sort((a: any, b: any) => {
        const aPos = a.position || 1;
        const bPos = b.position || 1;
        return aPos - bPos;
      });
    };
    const filterMaterials = (materials: any[]) => {
      if (!isEmpty(searchTerm)) {
        return materials.filter((material) => {
          return material?.name.toLowerCase().includes(searchTerm);
        });
      }
      return populatedMaterials;
    };

    if (populatedMaterials) {
      const filteredMaterials = filterMaterials(populatedMaterials);
      const sortedMaterials = sortMaterials(filteredMaterials);
      setAvailableMaterials(sortedMaterials);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [craftsmanMaterials, originalMaterials, searchTerm]);

  return { availableMaterials };
};
