import React from "react";
import { Settings } from "react-feather";
import async from "../../components/Async";
import AuthGuard from "./guards";
import { RoutePaths } from "../../pages/constants";

// const Company = async(() => import("../../pages/pages/Settings"));
const Materials = async(() => import("../../pages/pages/MaterialSettings"));

export const settingsRoutes = {
  id: "Settings",
  path: RoutePaths.Settings,
  icon: <Settings />,
  guard: AuthGuard,
  children: [
    {
      path: RoutePaths.SettingsMaterials,
      name: "Material Settings",
      component: Materials,
    },
  ],
  component: null,
};
