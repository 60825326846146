import { appConfigVar } from "../local";
import { gql } from "@apollo/client";

const updateAppConfig = (prop: {}) => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    ...prop,
  });
};

export const toggleSidebar = () => {
  const { sidebarOpen } = appConfigVar();
  updateAppConfig({ sidebarOpen: !sidebarOpen });
};
export const toggleModal = (value: any) => {
  const { modals } = appConfigVar();
  updateAppConfig({
    modals: {
      ...modals,
      ...value,
    },
  });
};
export const updateLoader = (loader: any) => {
  const { loaders } = appConfigVar();
  updateAppConfig({
    loaders: {
      ...loaders,
      ...loader,
    },
  });
};

export const toggleNetworkConnection = (connected: boolean) => {
  updateAppConfig({ hasNetworkConnection: connected });
};

export const updateCalendarTab = (tab: string) => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    activeTabs: {
      ...current.activeTabs,
      calendar: tab,
    },
  });
};
export const updateDashboardTab = (tab: string) => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    activeTabs: {
      ...current.activeTabs,
      dashboard: tab,
    },
  });
};

export const toggleLanguage = (appLanguage: "de" | "en") => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    appLanguage,
  });
};

export const updateAccount = (account: any) => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    account,
  });
};

export const UPSERT_DEVICE = gql`
  mutation UpsertDevice($input: DeviceInput!) {
    device: upsertDevice(input: $input) {
      fcmToken
    }
  }
`;
