import { gql } from "@apollo/client";

export const QUERY_CONTACTS = gql`
  query GetAllContacts($where: WhereInput) {
    contacts: getAllContacts(where: $where) {
      accountId
      name
      salutation
      role
    }
  }
`;
