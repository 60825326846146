import { opportunityConfigVar } from "../local";

export const updateSelectedOpportunity = (selectedOpportunity: any = {}) => {
  const current = opportunityConfigVar();
  opportunityConfigVar({
    ...current,
    selectedOpportunity,
  });
};

export const updateAvailableOpportunities = (key: string, value: any) => {
  const current = opportunityConfigVar();
  opportunityConfigVar({
    ...current,
    availableOpportunity: {
      ...current.availableOpportunity,
      [key]: value,
    },
  });
};
