import {
  CareGiverProps,
  ContactProps,
  MaterialProp,
  QuoteMaterialProps,
  SignatureProps,
  projectConfigInitialState,
} from "../../pages/types";
import {
  ConstructionDocs,
  DocumentTypes,
  INTERNAL_PROJECT_STATUS,
  allowedActionsFromStatus,
} from "../../pages/constants";
import { appConfigVar, projectConfigVar } from "../local";
import { getValueFromPath, isEmpty, isNil, omitBy } from "../../pages/utils";

import { RequestsQueue } from "pages/utils/queue";
import { gql } from "@apollo/client";
import { useSnackbar } from "notistack";

export const updateSelectedQuote = (selectedQuote: any = {}) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    selectedQuote,
  });
};
export const updateProjectTab = (tab: string) => {
  const current = appConfigVar();
  appConfigVar({
    ...current,
    activeTabs: {
      ...current.activeTabs,
      project: tab,
    },
  });
};

export const UPDATE_QUOTE = gql`
  mutation UpdateQuote($input: QuoteInput!) {
    updateQuote(input: $input) {
      id
      name
      status
    }
  }
`;
export const addToQuoteForm = (prop: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    quoteForm: {
      ...current.quoteForm,
      ...prop,
    },
  });
};
export const toggleMaterialInQuoteForm = (material: QuoteMaterialProps) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.materials || [];
  if (materials.some(({ id }) => id === material.id)) {
    addToQuoteForm({
      materials: materials.filter((m) => m.id !== material.id),
    });
    return;
  }
  addToQuoteForm({ materials: [...materials, { ...material }] });
};

export const toggleMaterialsInWizard = (material: any) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.selectedWizardMaterials || [];
  if (materials.some(({ id }) => id === material.id)) {
    addToQuoteForm({
      selectedWizardMaterials: materials.filter((m) => m.id !== material.id),
    });
    return;
  }
  addToQuoteForm({ selectedWizardMaterials: [...materials, { ...material }] });
};

export const updateMaterialInWizard = (id: string, quantity: number) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.selectedWizardMaterials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, quantity } : m
  );
  addToQuoteForm({
    selectedWizardMaterials: updatedMaterials,
  });
};

export const updateMaterialPriceInWizard = ({ id, ...rest }: any) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.selectedWizardMaterials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, ...rest } : m
  );
  addToQuoteForm({
    selectedWizardMaterials: updatedMaterials,
  });
};

export const updateMaterialDescriptionInWizard = (
  id: string,
  description: string
) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.selectedWizardMaterials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, description } : m
  );
  addToQuoteForm({
    selectedWizardMaterials: updatedMaterials,
  });
};

export const updateMaterialInQuoteForm = (id: string, quantity: number) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.materials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, quantity } : m
  );
  addToQuoteForm({
    materials: updatedMaterials,
  });
};

export const updateMaterialPriceInQuoteForm = ({ id, ...rest }: any) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.materials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, ...rest } : m
  );
  addToQuoteForm({
    materials: updatedMaterials,
  });
};

export const updateMaterialDescriptionInQuoteForm = (
  id: string,
  description: string
) => {
  const current = projectConfigVar();
  const materials = current.quoteForm.materials || [];
  const updatedMaterials = materials.map((m) =>
    m.id === id ? { ...m, description } : m
  );
  addToQuoteForm({
    materials: updatedMaterials,
  });
};

const updateSignature = (signature: Record<string, SignatureProps | null>) => {
  const current = projectConfigVar();
  const signatures = current.quoteForm.signatures || {};
  addToQuoteForm({
    signatures: {
      ...signatures,
      ...signature,
    },
  });
};

interface SigninProps {
  data: SignatureProps | null;
  tabIndx: number;
}

export const handleSigning = ({ data, tabIndx }: SigninProps) => {
  let signature: Record<string, SignatureProps | null> = {};
  if (tabIndx === 0) {
    signature["craftsman"] = data;
  } else {
    signature[`caregiver${tabIndx}`] = data;
  }
  updateSignature(signature);
};

export const clearSignatureModal = () => {
  addToQuoteForm({
    signatures: {
      craftsman: null,
      caregiver1: null,
      caregiver2: null,
      caregiver3: null,
    },
  });
};

export const emptyQuoteForm = () => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    quoteForm: {
      ...projectConfigInitialState.quoteForm,
    },
  });
};

const formatTerms = (values: any) => {
  const {
    financialSupport1,
    financialSupport2,
    financialSupport3,
    careServices1,
    careServices2,
    careServices3,
    caregiverQuoteAgreement,
  } = values;
  return {
    financialSupportPurpose: {
      answer1: financialSupport1,
      answer2: financialSupport2,
      answer3: financialSupport3,
    },
    receivingCareServices: {
      answer1: careServices1,
      answer2: careServices2,
      answer3: careServices3,
    },
    caregiverQuoteAgreement: {
      accepted: caregiverQuoteAgreement?.includes("accepted"),
      decisionTime: caregiverQuoteAgreement?.includes("decisionTime"),
    },
  };
};

const formatContacts = (values: any) => {
  const current = projectConfigVar();
  const signatures = current.quoteForm.signatures || {};
  const {
    tenantNumber,
    insuranceNumber1,
    insuranceNumber2,
    insuranceNumber3,
  } = values;
  const contacts: Record<string, ContactProps> = {
    caregiver1: {
      id: signatures.caregiver1?.id,
      tenantNumber,
      insuranceNumber: insuranceNumber1,
    },
    caregiver2: {
      id: signatures.caregiver2?.id,
      tenantNumber,
      insuranceNumber: insuranceNumber2,
    },
    caregiver3: {
      id: signatures.caregiver3?.id,
      tenantNumber,
      insuranceNumber: insuranceNumber3,
    },
  };
  let noContactsWithoutId = {};
  Object.keys(contacts).forEach((key) => {
    if (contacts[key].id) {
      noContactsWithoutId = {
        ...noContactsWithoutId,
        [key]: contacts[key],
      };
    }
  });
  return noContactsWithoutId;
};

const formatSignatures = () => {
  const current = projectConfigVar();
  const { quoteForm } = current;
  const signatures = quoteForm?.signatures || {};
  let noNilSignatures = {};
  Object.keys(signatures).forEach((key) => {
    if (signatures[key]) {
      noNilSignatures = {
        ...noNilSignatures,
        [key]: signatures[key],
      };
    }
  });
  return noNilSignatures;
};

interface ProcessSignatureFormProps {
  values: any;
  selectedProject: Record<string, any>;
}

export const processSignatureForm = ({
  values,
  selectedProject,
}: ProcessSignatureFormProps) => {
  const { prepayment, propertyManagement, defects, customerApproval } = values;
  const terms = formatTerms(values);
  const contact = formatContacts(values);
  const signatures = formatSignatures();
  const { isInvoice, isQuote } = allowedActionsFromStatus({
    status: selectedProject.internalStatus,
  });

  let data: Record<string, string | number> = {
    id: selectedProject.id,
  };
  let internalQuote: Record<string, any> = {
    signatures,
    terms,
    contact,
  };

  if (prepayment > 0) {
    data = {
      ...data,
      prepayment,
    };
  }
  if (propertyManagement) {
    internalQuote = {
      propertyManagement,
      ...internalQuote,
    };
  }

  if (!isInvoice) {
    return {
      ...data,
      internalStatus: isQuote
        ? INTERNAL_PROJECT_STATUS.CompleteQuotation
        : selectedProject.internalStatus,
      internalQuote,
    };
  }
  return {
    ...data,
    internalStatus: INTERNAL_PROJECT_STATUS.Done,
    invoice: {
      signatures,
      customerApproval,
      defects,
    },
  };
};

const quoteOrInvoiceDetailsIO = () => {
  const { quoteForm, selectedProject } = projectConfigVar();
  const { validityDate, customerNumber, quoteNumber } = { ...quoteForm };
  let data: {
    invoice: Record<string, any>;
    internalQuote: Record<string, any>;
    customerNumber?: number;
  } = {
    customerNumber: customerNumber
      ? Number(customerNumber)
      : selectedProject.customerNumber,
    internalQuote: {
      validityDate: validityDate && new Date(validityDate).toISOString(),
      quoteNumber: quoteNumber
        ? Number(quoteNumber)
        : getValueFromPath("internalQuote.quoteNumber", selectedProject),
    },
    invoice: {
      invoiceNumber: quoteNumber
        ? Number(quoteNumber)
        : getValueFromPath("invoice.invoiceNumber", selectedProject),
    },
  };
  return omitBy(data, (value) => isNil(value));
};

const quoteFormMaterialsIO = () => {
  const { quoteForm } = projectConfigVar();
  const quoteMaterials = quoteForm.materials || [];
  const materials = quoteMaterials?.map(
    ({ id, price, quantity, description, materialId, name, type }) => {
      if (materialId) {
        return {
          craftsmanName: name,
          craftsmanId: id,
          craftsmanPrice: price,
          craftsmanDescription: description,
          totalPrice: Number(price * quantity),
          materialId,
          quantity,
          type,
        };
      }
      return {
        materialId: id,
        price: Number(price),
        totalPrice: Number(price * quantity),
        quantity,
        craftsmanDescription: description,
        type,
      };
    }
  );

  return {
    materials,
    totalPrice: materials.reduce(
      (sum: number, m: any) => sum + m?.totalPrice,
      0
    ),
  };
};

const quoteFormStatusIO = ({
  currentStatus,
  formValues,
  isInvoice,
}: {
  isInvoice: boolean;
  currentStatus: string;
  formValues: Record<string, any>;
}) => {
  if (isInvoice) {
    const hasInvoiceNoAndMaterials =
      !isNil(formValues.invoiceNumber) &&
      (!isEmpty(formValues.materials) || !isEmpty(formValues.wizardMaterials));
    return hasInvoiceNoAndMaterials
      ? INTERNAL_PROJECT_STATUS.ConstructionApproval
      : currentStatus;
  }
  return currentStatus === INTERNAL_PROJECT_STATUS.NewQuotation
    ? INTERNAL_PROJECT_STATUS.OpenQuotation
    : currentStatus;
};

export const quoteFormWizardMaterialsIO = () => {
  const { quoteForm } = projectConfigVar();
  const materials =
    quoteForm?.selectedWizardMaterials?.map(
      ({ isWizard, quantity: materialQuantity, ...rest }) => {
        const quantity = materialQuantity || 1;
        return {
          ...rest,
          quantity,
          totalPrice: quantity * rest.price,
        };
      }
    ) || [];
  const totalPrice = materials.reduce(
    (sum: number, currValue) => sum + currValue.quantity * currValue.price,
    0
  );
  return {
    wizardMaterials: {
      materials,
      totalPrice,
    },
  };
};

export const quoteFormIO = (isEditingQuote: boolean) => {
  const { selectedProject } = projectConfigVar();
  const { isInvoice } = allowedActionsFromStatus({
    status: selectedProject.internalStatus,
    isEditingQuote,
  });
  const { wizardMaterials } = quoteFormWizardMaterialsIO();
  const { materials, totalPrice } = quoteFormMaterialsIO();
  const { customerNumber, internalQuote, invoice } = quoteOrInvoiceDetailsIO();
  const internalStatus = quoteFormStatusIO({
    isInvoice,
    currentStatus: selectedProject.internalStatus,
    formValues: {
      customerNumber,
      invoiceNumber: invoice.invoiceNumber,
      quoteNumber: internalQuote.quoteNumber,
      materials,
      wizardMaterials,
    },
  });

  if (!isInvoice || isEditingQuote) {
    return {
      id: selectedProject?.id,
      internalStatus,
      customerNumber,
      wizardMaterials,
      internalQuote: {
        materials,
        totalPrice,
        ...internalQuote,
      },
    };
  }

  return {
    id: selectedProject?.id,
    internalStatus,
    customerNumber,
    wizardMaterials,
    invoice: {
      materials,
      totalPrice,
      ...invoice,
    },
  };
};

export const packContacts = () => {
  const { selectedQuote } = projectConfigVar();
  const contacts: CareGiverProps[] = [];
  Object.keys(selectedQuote).forEach((key) => {
    if (key.includes("caregiver") && !!selectedQuote[key].id) {
      contacts.push({
        id: selectedQuote[key].id,
        name: selectedQuote[key].name,
      });
    }
  });

  return contacts;
};

const getFilledInsuranceCount = ({
  contacts = [],
  values,
}: {
  contacts: any[];
  values: any;
}) => {
  return contacts
    .map((contact, i) => {
      return Boolean(values[`insuranceNumber${i + 1}`]);
    })
    .filter((insuranceExists) => insuranceExists).length;
};

const getTotalSignatures = () => {
  const current = projectConfigVar();
  const { quoteForm } = current;
  return Object.values(quoteForm?.signatures).filter((value) => !!value).length;
};

const getOwnerShipType = () => {
  const { selectedQuote } = projectConfigVar();
  return selectedQuote?.ownershipType?.includes("Tenant") || false;
};

const isBeforeConstructionPhoto = (attachment: any) =>
  attachment?.documentType === DocumentTypes.Photo &&
  attachment?.documentName === ConstructionDocs.BeforeConstruction;

const isAfterConstructionPhoto = (attachment: any) =>
  attachment?.documentType === DocumentTypes.Photo &&
  attachment?.documentName === ConstructionDocs.AfterConstruction;

const hasConstructionPhotos = (attachments: any[], isInvoice: boolean) => {
  if (isInvoice) {
    return attachments.some(isAfterConstructionPhoto);
  }
  return attachments.some(isBeforeConstructionPhoto);
};

export const shouldSubmitQuoteForm = (values: any, attachments: any[]) => {
  const { isQuote, isInvoice } = allowedActionsFromStatus({
    status: values.internalStatus,
  });
  if (!hasConstructionPhotos(attachments, isInvoice)) {
    return false;
  }
  return isQuote
    ? !isInvoice &&
        !isNil(values.customerNumber) &&
        !isNil(values.internalQuote?.quoteNumber)
    : true;
};

export const getQuoteFormValidationMessages = (
  values: any,
  attachments: any[]
) => {
  const { isQuote, isInvoice } = allowedActionsFromStatus({
    status: values.internalStatus,
  });
  if (isQuote && isEmpty(values.customerNumber)) {
    return "Missing Customer Number";
  }
  if (isQuote && isEmpty(values.internalQuote?.quoteNumber)) {
    return "Missing Quote Number";
  }
  if (!hasConstructionPhotos(attachments, isInvoice)) {
    return "Please upload Photos first";
  }
  return "Something went wrong";
};

export const shouldSubmitSignatureForm = ({
  selectedProject,
  values,
}: {
  selectedProject: Record<string, any>;
  values: any;
}) => {
  const missingPropertyManagement = !values.propertyManagement;
  const missingTenantNumber = !values.tenantNumber;
  const contacts = packContacts();
  const totalInsuranceNumbers = getFilledInsuranceCount({
    values,
    contacts,
  });
  const ownerTypeTenant = getOwnerShipType();
  const totalSignatures = getTotalSignatures();
  const missingInsurance = contacts.length !== totalInsuranceNumbers;
  const hasAtleastOneSignature = totalSignatures >= 1;
  const fullySigned = totalSignatures === contacts.length + 1;
  const { isInvoice } = allowedActionsFromStatus({
    status: selectedProject.internalStatus,
  });
  if (isInvoice) {
    return fullySigned;
  } else if (
    ownerTypeTenant &&
    (missingPropertyManagement || missingTenantNumber)
  ) {
    return false;
  } else if (
    values.caregiverQuoteAgreement === "accepted" &&
    missingInsurance
  ) {
    return false;
  } else if (
    values.caregiverQuoteAgreement === "decisionTime" &&
    hasAtleastOneSignature
  ) {
    return true;
  } else if (values.caregiverQuoteAgreement === "accepted" && fullySigned) {
    return true;
  }
  return false;
};

export const getSignatureFormValidationMessages = ({
  selectedProject,
  values,
}: {
  selectedProject: Record<string, any>;
  values: any;
}) => {
  const missingPropertyManagement = !values.propertyManagement;
  const missingTenantNumber = !values.tenantNumber;
  const contacts = packContacts();
  const totalInsuranceNumbers = getFilledInsuranceCount({
    values,
    contacts,
  });
  const ownerTypeTenant = getOwnerShipType();
  const totalSignatures = getTotalSignatures();
  const missingInsurance = contacts.length !== totalInsuranceNumbers;
  const hasAtleastOneSignature = totalSignatures >= 1;
  const fullySigned = totalSignatures === contacts.length + 1;
  const { isInvoice } = allowedActionsFromStatus({
    status: selectedProject.internalStatus,
  });
  if (isInvoice) {
    return !fullySigned ? "All signatures required" : "";
  } else if (ownerTypeTenant && missingPropertyManagement) {
    return "Missing property management number";
  } else if (ownerTypeTenant && missingTenantNumber) {
    return "Missing tenant number";
  } else if (
    missingInsurance &&
    values.caregiverQuoteAgreement === "accepted"
  ) {
    return "Missing insurance number";
  } else if (
    !hasAtleastOneSignature &&
    values.caregiverQuoteAgreement === "decisionTime"
  ) {
    return "Craftsman signature needed";
  }
  return !fullySigned && values.caregiverQuoteAgreement === "accepted"
    ? "All signatures required"
    : "";
};

export const useRequestsOffline = ({
  handleOnline,
  hasNetworkConnection,
  requestId,
}: {
  handleOnline: (args: any) => Promise<any>;
  hasNetworkConnection: boolean;
  requestId: string;
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const requestQueue = new RequestsQueue();

  return {
    handleRequestsOffline: async (input: any) => {
      if (hasNetworkConnection) {
        return handleOnline(input);
      }
      return requestQueue.saveRequest(
        {
          requestId,
          ...input,
        },
        (msg: string) =>
          enqueueSnackbar(msg, {
            variant: "info",
          })
      );
    },
  };
};

interface QuoteMaterialProp extends MaterialProp {
  craftsmanId?: string;
  craftsmanName?: string;
  craftsmanDescription?: string;
  craftsmanPrice?: number;
}

export const transformQuoteMaterials = ({
  materials,
}: {
  materials?: QuoteMaterialProp[];
}) => {
  return (
    materials?.map(
      ({
        material = {},
        price = 0,
        quantity = 1,
        craftsmanId,
        craftsmanName,
        craftsmanPrice = 0,
        craftsmanDescription,
      }: any) => {
        if (craftsmanId) {
          return {
            ...material,
            description: craftsmanDescription,
            id: craftsmanId,
            name: craftsmanName,
            materialId: material?.id,
            price: craftsmanPrice,
            quantity,
          };
        }
        return {
          ...material,
          description: craftsmanDescription || material.description,
          price,
          quantity,
        };
      }
    ) || []
  );
};

export const cloneQuoteMaterialsForInvoice = (
  materials?: QuoteMaterialProp[]
) => {
  const invoice: {
    totalPrice: number;
    materials: any[];
  } = {
    materials: [],
    totalPrice: 0,
  };
  const packedMaterials =
    materials?.map(
      ({
        material = {},
        price = 0,
        craftsmanId,
        craftsmanName,
        craftsmanPrice = 0,
        craftsmanDescription,
        quantity = 1,
      }: any) => {
        if (craftsmanId) {
          return {
            craftsmanId,
            craftsmanName,
            craftsmanPrice,
            craftsmanDescription,
            quantity,
            price,
            materialId: material?.id,
            totalPrice: craftsmanPrice * quantity,
          };
        }
        return {
          price,
          quantity,
          materialId: material?.id,
          craftsmanDescription,
          totalPrice: price * quantity,
        };
      }
    ) || [];
  invoice.totalPrice = packedMaterials.reduce((sum, material) => {
    return sum + material.totalPrice;
  }, 0);
  invoice.materials = packedMaterials;
  return invoice;
};

export const updateProjectFilters = (filters: any) => {
  const current = projectConfigVar();
  projectConfigVar({ ...current, filters });
};

export const updateSelectedQuoteMarker = (selectedQuoteMarker: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    selectedQuoteMarker,
  });
};

export const removeSelectedQuoteMarker = () => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    selectedQuoteMarker: {},
  });
};

export const updateMapData = (mapData: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    mapData,
  });
};
