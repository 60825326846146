export type SignInType = {
  email: string;
  password: string;
};

export type SignUpType = SignInType & {
  name: string;
  company: string;
};

export type ResetPasswordType = Pick<SignInType, "email">;

export type AuthConfigProps = {
  user: {
    displayName: string | null;
    email: string | null;
  } | null;
  isSignedIn: boolean;
};

export const authConfigInitialState: AuthConfigProps = {
  isSignedIn: false,
  user: null,
};
