import { FC, Fragment, useEffect, useState } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import { updateAuthConfig } from "./domains/mutations";
import Loader from "./components/Loader";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "",
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID || "",
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL || "",
};

firebase.initializeApp(firebaseConfig);

export const FirebaseProvider: FC = ({ children }) => {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        const { displayName, email } = user;
        updateAuthConfig({
          user: {
            displayName,
            email,
          },
          isSignedIn: true,
        });
      } else {
        updateAuthConfig({ user: null, isSignedIn: false });
      }
      setLoading(false);
    });
  }, []);

  if (loading) {
    return <Loader />;
  }
  return <Fragment>{children}</Fragment>;
};

interface SignInProps {
  email: string;
  password: string;
}

interface PasswordResetProps {
  email: string;
  actionCodeSettings?: any;
}

interface ConfirmPasswordResetProps {
  newPassword: string;
  code: string;
}

export const firebaseActions = {
  logOut: async () => await firebase.auth().signOut(),
  signIn: async ({ email, password }: SignInProps) =>
    await firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(
        async () =>
          await firebase.auth().signInWithEmailAndPassword(email, password)
      )
      .catch((error) => error),
  register: async ({ email, password }: SignInProps) =>
    await firebase.auth().createUserWithEmailAndPassword(email, password),
  sendPasswordResetEmail: async ({
    email,
    actionCodeSettings = null,
  }: PasswordResetProps) =>
    await firebase.auth().sendPasswordResetEmail(email, actionCodeSettings),
  confirmPasswordReset: async ({
    code,
    newPassword,
  }: ConfirmPasswordResetProps) =>
    await firebase.auth().confirmPasswordReset(code, newPassword),
};
