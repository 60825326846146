import { gql, useMutation } from "@apollo/client";
import { groupBy, isEmpty, isFinite } from "pages/utils";

import { projectConfigVar } from "domains/local";

export const ADD_MATERIAL = gql`
  mutation CreateMaterial($input: MaterialInput!) {
    createdMaterial: createMaterial(input: $input) {
      id
      price
      name
      categoryId
      description
    }
  }
`;
export const CREATE_MATERIAL_CATEGORY = gql`
  mutation CreateMaterialCategory($input: MaterialCategoryInput!) {
    materialCategory: createMaterialCategory(input: $input) {
      id
      categoryName
      categoryDescription
    }
  }
`;
export const UPSERT_CRAFTSMAN_MATERIAL = gql`
  mutation UpsertCraftsmanMaterial($input: CraftsmanMaterialInput!) {
    craftsmanMaterial: upsertCraftsmanMaterial(input: $input) {
      id
      material {
        id
        categoryId
      }
      alternativeName
      alternativePrice
      alternativeDescription
      favorite
    }
  }
`;
export const UPSERT_MATERIAL = gql`
  mutation UpsertMaterial($input: MaterialInput!) {
    material: upsertMaterial(input: $input) {
      id
      price
      name
      categoryId
      description
    }
  }
`;

export const transformCraftsmanMaterial = (craftsmanMaterial: any) => {
  const {
    alternativeName,
    alternativePrice,
    alternativeDescription,
    material,
    ...rest
  } = craftsmanMaterial;
  return {
    ...rest,
    name: alternativeName,
    price: alternativePrice,
    description: alternativeDescription,
    materialId: material?.id,
    categoryId: material?.categoryId,
  };
};

const convertToOriginalMaterialShape = (
  craftsmanMaterial: any,
  originalMaterials: any[]
) => {
  const material = transformCraftsmanMaterial(craftsmanMaterial);
  const foundMaterial = originalMaterials.find(
    (originalMaterial) => originalMaterial.id === material?.materialId
  );
  return {
    ...material,
    editable: foundMaterial?.editable,
  };
};

export const getVisibleMaterials = (
  materials: any[],
  selectedMaterials: any[] = []
) => {
  if (!isEmpty(selectedMaterials)) {
    const selectedMaterialIds = selectedMaterials.map((m) => m.id);
    return materials.filter(
      (m) => !m.hidden || selectedMaterialIds.includes(m.id)
    );
  }
  return materials.filter((m) => !m.hidden);
};

export const removeDuplicateMaterials = (
  craftsmanMaterials: any[],
  originalMaterials: any[]
) => {
  const materials = [
    ...craftsmanMaterials.map((material) =>
      convertToOriginalMaterialShape(material, originalMaterials)
    ),
  ];
  const materialIds = materials.map((material) => material.materialId);
  originalMaterials.forEach((material) => {
    if (!materialIds.includes(material.id)) {
      materials.push(material);
    }
  });
  return materials;
};

export const populateMaterials = (materials: any[] = []) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    materials,
  });
};

export const populateCraftsmanMaterials = (craftsmanMaterials: any[] = []) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    craftsmanMaterials,
  });
};

export const populateMaterialCategories = (materialCategories: any[] = []) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    materialCategories,
  });
};

export const groupMaterials = (materials: any[]) => {
  const materialsByFavorite = groupBy(materials, "favorite");
  let nonFavoriteMaterials: any[] = [];
  Object.keys(materialsByFavorite).forEach((key) => {
    if (key === "true") {
      return;
    }
    nonFavoriteMaterials = [
      ...materialsByFavorite[key],
      ...nonFavoriteMaterials,
    ];
  });
  const nonFavoritesByCategory = groupBy(nonFavoriteMaterials, "categoryId");
  let materialsByCategory = {
    ...nonFavoritesByCategory,
  };
  if (materialsByFavorite["true"]) {
    materialsByCategory = {
      Favourite: materialsByFavorite["true"],
      ...materialsByCategory,
    };
  }
  return materialsByCategory;
};

const updateCraftsmanMaterial = (updatedMaterial: any) => {
  const current = projectConfigVar();
  const { craftsmanMaterials } = current;
  const materialExists = craftsmanMaterials.some(
    (material) => material?.id === updatedMaterial.id
  );
  if (materialExists) {
    projectConfigVar({
      ...current,
      craftsmanMaterials: craftsmanMaterials.map((material) =>
        material?.id === updatedMaterial.id ? updatedMaterial : material
      ),
    });
  } else {
    projectConfigVar({
      ...current,
      craftsmanMaterials: [...craftsmanMaterials, updatedMaterial],
    });
  }
};

const updateOriginalMaterial = (updatedMaterial: any) => {
  const current = projectConfigVar();
  const { materials } = current;
  const materialExists = materials.some(
    (material) => material?.id === updatedMaterial.id
  );
  if (materialExists) {
    projectConfigVar({
      ...current,
      materials: materials.map((material) =>
        material?.id === updatedMaterial.id ? updatedMaterial : material
      ),
    });
  } else {
    projectConfigVar({
      ...current,
      materials: [...materials, updatedMaterial],
    });
  }
};

export const updateMaterial = ({
  material,
  isCraftsmanMaterial = false,
}: {
  material: any;
  isCraftsmanMaterial?: boolean;
}) => {
  if (isCraftsmanMaterial) {
    updateCraftsmanMaterial(material);
  } else {
    updateOriginalMaterial(material);
  }
};

export const useUpsertCraftsmanMaterial = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback?: (craftsmanMaterial: any) => void;
  onErrorCallback?: (error: any) => void;
}) => {
  const [
    upsertCraftsmanMaterial,
    { loading: upsertCraftsmanMaterialLoader },
  ] = useMutation(UPSERT_CRAFTSMAN_MATERIAL, {
    onCompleted: (data) => {
      if (data?.craftsmanMaterial) {
        updateCraftsmanMaterial(data.craftsmanMaterial);
        onSuccessCallback?.(data.craftsmanMaterial);
      }
    },
    onError: (error) => {
      onErrorCallback?.(error);
    },
  });

  return {
    upsertCraftsmanMaterial,
    upsertCraftsmanMaterialLoader,
  };
};

export const isAllowedToModifyAllMaterials = (user?: any) => {
  return (
    user?.email.includes("tech@sorgenfrei-zuhause.de") ||
    //process.env.NODE_ENV === "development" ||
    false
  );
};

export const hasEmptyMaterialDescription = (description: any) => {
  return (
    description.length <= 2 && isEmpty(description?.[0]?.children[0]?.text)
  );
};

const processMaterialInput = (values: Record<string, any>, key: string) => {
  const matchKey = key.toLowerCase();
  if (matchKey.includes("description")) {
    return hasEmptyMaterialDescription(values[key])
      ? {}
      : { [key]: JSON.stringify(values[key]) };
  }
  if (matchKey.includes("categoryid") && values[key]?.value) {
    return {
      [key]: values[key].value,
    };
  }
  if (values[key] || isFinite(values[key])) {
    return { [key]: values[key] };
  }
  return {};
};

const processAnyMaterialInput = (values: Record<string, any>) => {
  let input: Record<string, any> = {};
  Object.keys(values).forEach((key) => {
    input = {
      ...input,
      ...processMaterialInput(values, key),
    };
  });
  return input;
};

export const processMaterialInputs = (values: any) => {
  const input = processAnyMaterialInput(values);
  // formik on edit uses alternativeName, alternativePrice ...we convert those to name, price
  Object.keys(input).forEach((key) => {
    if (key.includes("alternative")) {
      input[key.replace("alternative", "").toLowerCase()] = input[key];
      delete input[key];
    }
  });
  return input;
};

export const processCraftsmanMaterialInputs = (values: any) => {
  const input = processAnyMaterialInput(values);
  return input;
};

export const formatMaterial = (values: any) => processMaterialInputs(values);
export const formatCraftsmanMaterials = (values: any) =>
  processCraftsmanMaterialInputs(values);

export const useUpsertMaterial = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback?: (material: any) => void;
  onErrorCallback?: (error: any) => void;
}) => {
  const [upsertMaterial, { loading: upsertMaterialLoader }] = useMutation(
    UPSERT_MATERIAL,
    {
      onCompleted: (data) => {
        if (data.material) {
          updateOriginalMaterial(data.material);
          onSuccessCallback?.(data.material);
        }
      },
      onError: (error) => {
        onErrorCallback?.(error);
      },
    }
  );

  return {
    upsertMaterial,
    upsertMaterialLoader,
  };
};

const addMaterialCategory = (materialCategory: any) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    materialCategories: [...current.materialCategories, materialCategory],
  });
};

export const useCreateMaterialCategory = ({
  onSuccessCallback,
  onErrorCallback,
}: {
  onSuccessCallback?: (material: any) => void;
  onErrorCallback?: (error: any) => void;
}) => {
  const [
    createMaterialCategory,
    { loading: createMaterialCategoryLoader },
  ] = useMutation(CREATE_MATERIAL_CATEGORY, {
    onCompleted: (data) => {
      if (data?.materialCategory) {
        addMaterialCategory(data.materialCategory);
        onSuccessCallback?.(data.materialCategory);
      }
    },
    onError: (error) => {
      onErrorCallback?.(error);
    },
  });

  return {
    createMaterialCategory,
    createMaterialCategoryLoader,
  };
};

export const updateMaterialSearchTerm = (materialSearchTerm: string) => {
  const current = projectConfigVar();
  projectConfigVar({
    ...current,
    materialSearchTerm,
  });
};
