import React, { ElementType, Fragment } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  RouteComponentProps,
} from "react-router-dom";
import { landingRoutes, authRoutes, protectedRoutes } from "./index";

import DashboardLayout from "../layouts/Dashboard";
import AuthLayout from "../layouts/Auth";
import LandingLayout from "../layouts/Landing";
import Page404 from "../pages/auth/Page404";
import { AllRoutesChildType } from "../pages/types/routes";
import AppUrlListener from "../components/AppUrlListener";

const childRoutes = (Layout: ElementType, routes: Array<any>) =>
  routes.map(
    ({ component: Component, guard, children, path }, index: number) => {
      const Guard = guard || Fragment;
      if (children) {
        return children.map((element: AllRoutesChildType, index: number) => {
          const Guard = element.guard || Fragment;

          return (
            <Route
              key={index}
              path={element.path}
              exact
              render={(props: RouteComponentProps) => (
                <Guard>
                  <Layout>
                    <element.component {...props} />
                  </Layout>
                </Guard>
              )}
            />
          );
        });
      }
      if (Component) {
        return (
          <Route
            key={index}
            path={path}
            exact
            render={(props) => (
              <Guard>
                <Layout>
                  <Component {...props} />
                </Layout>
              </Guard>
            )}
          />
        );
      }
      return null;
    }
  );

const Routes = () => (
  <>
    <AppUrlListener />
    <Router>
      <Switch>
        {childRoutes(LandingLayout, landingRoutes)}
        {childRoutes(DashboardLayout, protectedRoutes)}

        {childRoutes(AuthLayout, authRoutes)}

        <Route
          render={() => (
            <AuthLayout>
              <Page404 />
            </AuthLayout>
          )}
        />
      </Switch>
    </Router>
  </>
);

export default Routes;
